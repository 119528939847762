import React from 'react';
import './alert.scss';

import { Alert as RsAlert, AlertProps as RsAlertProps } from 'reactstrap';

interface AlertProps extends RsAlertProps {
  className?: string;
}

export const Alert = ({ children, className = '', ...props }: AlertProps) => {
  return (
    <RsAlert {...props} className={className}>
      {children}
    </RsAlert>
  );
};
