import './select.scss';

import React from 'react';
import { noop } from 'lodash';
import { declassify } from '../../../util';

interface SelectItem {
  value: string;
  label?: string;
}

interface SelectProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  options: SelectItem[];
  selected: string;
  small?: boolean;
  invalid?: boolean;
  onChange?: (value: string) => void;
}

export const Select = ({ selected, options, onChange = noop, className = '', small = false, invalid = false, ...props }: SelectProps) => {
  return (
    <div className={declassify('select', { small }, { invalid }, className)}>
      <select value={selected} onChange={e => onChange(e.target.value)} className="custom-select" {...props}>
        {options.map(o => (
          <option key={o.value} value={o.value}>
            {o.label ?? o.value}
          </option>
        ))}
      </select>
      <i className="las la-sort" />
    </div>
  );
};
