import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { connect } from 'react-redux';
import CustomRedirect from 'app/shared/util/custom-redirect';

const NoPermissionComponent = (props: StateProps) => {
  if (props.hasPermission) {
    return <CustomRedirect to="/" />;
  }

  return (
    <div>
      <div className="alert alert-danger">Nemate pravo pristupa sustavu eGrađevinski dnevnik!</div>
    </div>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  hasPermission: !hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.NO_PERMISSION]),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(NoPermissionComponent);
