import { uniqueId } from 'lodash';
import React from 'react';
import { Typeahead, TypeaheadProps, TypeaheadModel } from 'react-bootstrap-typeahead';

import './predictive-input.scss';
import { declassify } from 'app/shared/lib/util';

export interface PredictiveInputProps<T extends TypeaheadModel> extends TypeaheadProps<T> {
  prependIcon?: string;
  className?: string;
  prependIconClickable?: boolean;
  onPrependIconClick?: any;
  clearSearchFieldOnBlur?: boolean;
}

function PredictiveInputWrapper(
  {
    id = uniqueId(),
    selected,
    className = '',
    placeholder = 'Search...',
    prependIcon = 'las la-search',
    multiple = false,
    open,
    prependIconClickable = false,
    onPrependIconClick,
    minLength = 0,
    ...props
  }: PredictiveInputProps<any>,
  ref: any
) {
  const handlePrependIconClick = () => {
    if (!open) {
      ref.current.focus();
    }
    onPrependIconClick();
  };

  return (
    <Typeahead
      id={id + `-dropdown-predictive`}
      className={declassify(selected && selected.length ? 'rbt-selected' : '', className)}
      selected={selected}
      multiple={multiple}
      minLength={minLength}
      placeholder={placeholder}
      open={open}
      ref={ref}
      {...props}
    >
      {!(selected?.length && multiple) ? (
        <span className="predictive-prepend-icon" {...(prependIconClickable && { onClick: handlePrependIconClick })}>
          <i className={prependIcon} />
        </span>
      ) : null}
    </Typeahead>
  );
}

export const PredictiveInputPure = React.forwardRef(PredictiveInputWrapper);
