import './upload-progress.scss';

import React from 'react';
import { declassify } from '../../../util';

interface UploadProgressItem {
  fileName: string;
  progress: number;
  size: string;
}

interface UploadProgressProps {
  items: UploadProgressItem[];
  done: boolean;
  doneMessage?: string;
  message?: string;
  headerText?: string;
}

export const UploadProgress = ({
  items,
  done,
  message = 'Molimo pričekajte...',
  doneMessage = 'Učitavanje dovršeno',
  headerText = 'Učitavanje podataka',
}: UploadProgressProps) => {
  return (
    <div className="upload-progress">
      <div className="upload-progress-header">
        <span>{headerText}</span>
      </div>
      <div className="upload-loader">
        {done ? (
          <>
            <i className="las la-check"></i>
            <div className="upload-loader-message">{doneMessage}</div>
          </>
        ) : (
          <>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">{message}</span>
            </div>
            <div className="upload-loader-message">{message}</div>
          </>
        )}
      </div>
      <div className="upload-progress-items">
        {items.map((item, ix) => (
          <div className="upload-progress-item" key={ix}>
            <div className="d-flex align-items-center">
              {item.progress === 100 ? <i className="las la-check"></i> : <i className="las la-file"></i>}
              <span className="file-name">{item.fileName}</span>
              <span className="file-size ml-auto">{item.size}</span>
            </div>

            <div className="progress-wrap">
              <span className={declassify('progress', { done: item.progress === 100 })} style={{ width: `${item.progress}%` }}></span>
              <span className={declassify('progress-empty', { done: item.progress === 100 })}></span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
