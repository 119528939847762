import { valuesIn } from 'lodash';

export interface ClassesAsProps {
  [name: string]: boolean;
}

export type ClassesAsArray = string[];

export type Classifiable = string | ClassesAsProps | ClassesAsArray;

export function classify(...classes: Classifiable[]): ClassesAsArray {
  const result = classes
    .map(value => {
      if (isString(value)) {
        return value.split(' ');
      } else if (isArray(value)) {
        return value.map(c => classify(c)).reduce(arrayReducer, []);
      }
      return Object.keys(value)
        .map(key => (value[key] ? classify(key) : []))
        .filter(v => v.length > 0)
        .reduce(arrayReducer, []);
    })
    .reduce(arrayReducer, []);

  return noDupes(noEmpty(result));

  function arrayReducer(arr: string[], item: string[]) {
    return [...arr, ...item];
  }
}

export function declassify(...classes: Classifiable[]): string {
  return classify(...classes).join(' ');
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isArray<T>(value: unknown): value is T[] {
  return value instanceof Array;
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date;
}

export function noDupes<T = any>(inArray: T[]): T[] {
  return [...new Set(inArray)];
}

export function noEmpty<T = any>(inArray: T[]): T[] {
  return inArray.filter(v => !isEmpty(v));
}

export function isEmpty(value: any): boolean {
  if (isArray(value)) {
    return value.filter(v => !isEmpty(v)).length === 0;
  }
  if (undefined === value || value === '' || value === null || value === false) {
    return true;
  }
  if (isDate(value)) {
    return false;
  }
  if (typeof value === 'object') {
    return valuesIn(value).filter(v => !isEmpty(v)).length === 0;
  }
  return false;
}

export function range(from: number, to: number) {
  return new Array(to - from + 1).fill(null).map((_, index) => index + from);
}
