import React, { useEffect, useState } from 'react';
import { Activities, Alert, Button } from 'app/shared/lib';
import axios from 'axios';
import { showToast } from 'app/shared/reducers/toast.util';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import './notifications.scss';
import { ACTION_TYPES as NOTIFICATIONS_DISPATCH_ACTIONS } from 'app/shared/reducers/notifications';
import NotificationItem from 'app/modules/ednevnik/notifications/notification-item-component';
import { v4 as uuid } from 'uuid';
import { Notification } from 'app/modules/ednevnik/notifications/notifications-model';
import { messages } from 'app/config/constants';
import { Loader } from 'app/shared/loader/loader';

export const ShowNotifications = () => {
  const dispatch = useDispatch();
  const authenticationState = useSelector((state: IRootState) => state.authentication);
  const [notifications, setNotifications] = useState<Notification[]>();
  const notificationsState = useSelector((state: IRootState) => state.notificationsState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchNotificationsForUser(authenticationState.account.id_user);
  }, []);

  const fetchNotificationsForUser = (idUser: number) => {
    setIsLoading(true);

    axios
      .get(`/api/notifications/${idUser}`)
      .then(res => {
        setNotifications(res.data);
        setIsLoading(false);
      })
      .catch(error => {
        showToast(toast.error, error.response.data.errorMessage);
        setIsLoading(false);
      });
  };

  const setAllNotifications = (receivedNotifications: Notification[]) => {
    setNotifications(receivedNotifications);
  };

  const onCloseNotifications = () => {
    dispatch({ type: NOTIFICATIONS_DISPATCH_ACTIONS.SET_NOTIFICATIONS, shown: false });
  };

  const deactivateAllNotifications = () => {
    setIsLoading(true);

    axios
      .delete(`/api/notifications/deactivate-all?idUser=${authenticationState.account.id_user}`)
      .then(response => {
        setIsLoading(false);
        setNotifications(response.data);
        showToast(toast.success, messages.DATA_SUCCESSFULLY_DELETED);
      })
      .catch(() => {
        setIsLoading(false);
        showToast(toast.error, messages.DELETING_ERROR);
      });
  };

  const isDeleteAllDisabled = () => {
    return notifications?.length === 0 || notifications?.every(notification => notification?.buildingDiaries?.length > 0);
  };

  return (
    <div id="app-notifications-view" hidden={!notificationsState.notificationsShown}>
      <Activities isOpen={notificationsState.notificationsShown} onClose={onCloseNotifications} removeFooterOverflow>
        {isLoading ? (
          <Loader />
        ) : notifications?.length === 0 ? (
          <div className="activity-item">
            <Alert color="info" className="w-100">
              Nemate nepročitanih obavijesti.
            </Alert>
          </div>
        ) : (
          <>
            {isLoading ? (
              <>
                <div className="mb-2 d-flex justify-content-end">
                  <Button label="Obriši sve" color="primary" prependIcon="las la-trash" isLoading />
                </div>
                <hr />
              </>
            ) : (
              <>
                <div className="mb-2 d-flex justify-content-end">
                  <Button
                    label="Obriši sve"
                    color="primary"
                    onClick={() => deactivateAllNotifications()}
                    prependIcon="las la-trash"
                    disabled={isDeleteAllDisabled()}
                  />
                </div>
                <hr />
              </>
            )}
            {notifications?.map(notification => {
              return (
                <NotificationItem
                  key={uuid()}
                  notification={notification}
                  idUser={authenticationState.account.id_user}
                  setAllNotifications={setAllNotifications}
                />
              );
            })}
          </>
        )}
      </Activities>
    </div>
  );
};
