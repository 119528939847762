import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

interface CustomRedirectProps {
  to: string;
  search?: string;
}

export const CustomRedirect = (props: CustomRedirectProps) => {
  const history = useHistory();

  useEffect(() => {
    if (props.search) {
      history.replace(props.to + props.search);
    } else {
      history.replace(props.to);
    }
  }, []);

  return null;
};

export default CustomRedirect;
