import { noop } from 'lodash';
import React, { useCallback, useRef } from 'react';

import { PredictiveInputPure, PredictiveInputProps } from './PredictiveInputPure';

export const PredictiveInput = ({ onBlur = noop, clearSearchFieldOnBlur = false, ...props }: PredictiveInputProps<any>) => {
  const ref: any = useRef();
  const blur = useCallback(ev => {
    onBlur(ev);
    if (clearSearchFieldOnBlur) {
      ref.current.setState(s => ({ ...s, text: '' }));
    }
  }, []);

  return <PredictiveInputPure onBlur={blur} ref={ref} {...props} />;
};
