import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import ErrorBoundary from 'app/shared/error/error-boundary';
import CustomRedirect from 'app/shared/util/custom-redirect';
import { Loader } from 'app/shared/loader/loader';
import { Storage } from 'react-jhipster';
import { LOCAL_STORAGE_KEYS } from 'app/shared/util/local-storage-keys';

interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];
}

export interface IPrivateRouteProps extends IOwnProps, StateProps {}

export const PrivateRouteComponent = ({
  component: Component,
  sessionHasBeenFetched,
  isAuthorized,
  canSelectRole,
  hasAnyAuthorities = [],
  ...rest
}: IPrivateRouteProps) => {
  const history = useHistory();

  useEffect(() => {
    if (!sessionHasBeenFetched && !Storage.local.get(LOCAL_STORAGE_KEYS.PENDING_AUTHENTICATION)) {
      Storage.local.set(LOCAL_STORAGE_KEYS.PENDING_AUTHENTICATION, true);
    } else if (sessionHasBeenFetched && Storage.local.get(LOCAL_STORAGE_KEYS.PENDING_AUTHENTICATION)) {
      Storage.local.remove(LOCAL_STORAGE_KEYS.PENDING_AUTHENTICATION);
    }
  });

  const checkAuthorities = props => {
    if (isAuthorized || (!isAuthorized && canSelectRole)) {
      return (
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      );
    } else {
      if (sessionHasBeenFetched) {
        history.push('/no-permission');
      } else {
        return (
          <div>
            {!sessionHasBeenFetched && <Loader redirect={true} />}
            <CustomRedirect to={history.location.pathname} search={history.location.search} />
          </div>
        );
      }
    }
  };

  const renderRedirect = props => {
    return checkAuthorities(props);
  };

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);

  return <Route {...rest} render={renderRedirect} />;
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

const mapStateToProps = ({ authentication: { account, sessionHasBeenFetched } }: IRootState, { hasAnyAuthorities = [] }: IOwnProps) => ({
  isAuthorized: hasAnyAuthority(account.authorities, hasAnyAuthorities),
  canSelectRole: account.canSelectRole,
  sessionHasBeenFetched,
});

type StateProps = ReturnType<typeof mapStateToProps>;

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */
export const PrivateRoute = connect(mapStateToProps, null, null, { pure: false })(PrivateRouteComponent);

export default PrivateRoute;
