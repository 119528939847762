export const ACTION_TYPES = {
  SET_GLOBAL_NOTIFICATION: 'LAYOUT/SET_GLOBAL_NOTIFICATION',
};

const initialState: any = {
  globalNotificationShown: false,
};

export type GlobalNotificationState = Readonly<typeof initialState>;

export default (state: GlobalNotificationState = initialState, action): GlobalNotificationState => {
  switch (action.type) {
    case ACTION_TYPES.SET_GLOBAL_NOTIFICATION: {
      return {
        ...state,
        globalNotificationShown: action.shown,
      };
    }
    default:
      return state;
  }
};
