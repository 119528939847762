import './card.scss';

import React, { PropsWithChildren } from 'react';
import { Card as RsCard, CardProps as RsCardProps } from 'reactstrap';
import { declassify } from '../../../util';

interface CardProps extends RsCardProps {
  shadow?: boolean;
}

export const Card = ({ shadow = false, className = '', children, ...props }: PropsWithChildren<CardProps>) => {
  return (
    <RsCard {...props} className={declassify(className, { 'card-shadow': shadow })}>
      {children}
    </RsCard>
  );
};
