import Keycloak from '../shared/util/keycloak.js';
import { Storage } from 'react-jhipster';
import { AUTH_TOKEN_KEY, successLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import jwt from 'jwt-decode';
import { clearLocalStorageExceptPendingAuthentication } from 'app/shared/util/local-storage-keys';
import { ACTION_TYPES } from 'app/shared/reducers/keycloak';

let keycloak = Keycloak();

export const initKeycloak = dispatch => {
  axios.get('api/keycloak-properties').then(response => {
    keycloak = Keycloak({
      url: response.data.keycloakUrl,
      realm: response.data.keycloakRealm,
      clientId: response.data.keycloakClientId,
    });

    dispatch({ type: ACTION_TYPES.SET_AUTO_REDIRECT_TO_NIAS, payload: { autoRedirectToNias: response.data.autoRedirectToNias } });

    keycloak
      .init({
        onLoad: 'check-sso',
      })
      .success(function (auth) {
        if (auth) {
          axios.post('api/authenticate', { keycloakToken: keycloak.token, keycloakRefreshToken: keycloak.refreshToken }).then(res => {
            clearLocalStorageExceptPendingAuthentication();
            const bearerToken = res.headers.authorization;
            const token = bearerToken.slice(7, bearerToken.length);
            Storage.local.set(AUTH_TOKEN_KEY, token);

            const user = jwt(token);
            dispatch(successLogin(user));
          });
        }
      });
  });
};

export function fetchAndLogoutKeycloak() {
  axios.get('api/keycloak-properties').then(response => {
    keycloak = Keycloak({
      url: response.data.keycloakUrl,
      realm: response.data.keycloakRealm,
      clientId: response.data.keycloakClientId,
    });
    keycloak.init({
      onLoad: 'check-sso',
    });
    localStorage.clear();
    keycloak.logout();
  });
}

export function useKeycloak() {
  return keycloak;
}
