import { Storage } from 'react-jhipster';

export const LOCAL_STORAGE_KEYS = {
  BUILDING_DIARY_ACTIVE_TAB: 'BUILDING_DIARY_ACTIVE_TAB',
  BUILDING_DIARY_ACTIVE_VIEW: 'BUILDING_DIARY_ACTIVE_VIEW',
  BUILDING_DIARIES_TABLE_PAGING_SETTINGS: 'BUILDING_DIARIES_TABLE_PAGING_SETTINGS',
  BUILDING_DIARIES_CARD_PAGING_SETTINGS: 'BUILDING_DIARIES_CARD_PAGING_SETTINGS',
  BUILDING_DIARIES_TABLE_FILTERS: 'BUILDING_DIARIES_TABLE_FILTERS',

  BUILDING_SUBDIARY_ACTIVE_TAB: 'BUILDING_SUBDIARY_ACTIVE_TAB',
  BUILDING_SUBDIARY_ACTIVE_VIEW: 'BUILDING_SUBDIARY_ACTIVE_VIEW',
  BUILDING_SUBDIARIES_TABLE_PAGING_SETTINGS: 'BUILDING_SUBDIARIES_TABLE_PAGING_SETTINGS',
  BUILDING_SUBDIARIES_CARD_PAGING_SETTINGS: 'BUILDING_SUBDIARIES_CARD_PAGING_SETTINGS',
  USER_SUBDIARY_TEXT_WORK_LOG_VIEW_CARD_PAGING_SETTINGS: 'USER_SUBDIARY_TEXT_WORK_LOG_VIEW_CARD_PAGING_SETTINGS',

  CHAMBER_MEMBER_ACTIVITY_SHOWN: 'CHAMBER_MEMBER_ACTIVITY_SHOWN',

  SITE_CONSTRUCTOR_DELETED: 'SITE_CONSTRUCTOR_DELETED',
  SITE_SUPERVISOR_DELETED: 'SITE_SUPERVISOR_DELETED',

  PENDING_AUTHENTICATION: 'PENDING_AUTHENTICATION',

  BUILDING_SUBDIARY: 'BUILDING_SUBDIARY',
  SITE_CONSTRUCTOR: 'SITE_CONSTRUCTOR',
  SITE_SUPERVISOR: 'SITE_SUPERVISOR',

  WORK_LOG_SELECTED_DATE: 'WORK_LOG_SELECTED_DATE',
};

export const clearLocalStorage = () => {
  Object.values(LOCAL_STORAGE_KEYS).forEach(value => Storage.local.remove(value));
};

export const clearBuildingDiaryPagingSettings = () => {
  Storage.local.remove(LOCAL_STORAGE_KEYS.BUILDING_DIARIES_TABLE_PAGING_SETTINGS);
  Storage.local.remove(LOCAL_STORAGE_KEYS.BUILDING_DIARIES_CARD_PAGING_SETTINGS);
};

export const clearLocalStorageExceptPendingAuthentication = () => {
  Object.values(LOCAL_STORAGE_KEYS)
    .filter(key => key !== LOCAL_STORAGE_KEYS.PENDING_AUTHENTICATION)
    .forEach(value => Storage.local.remove(value));
};

export const clearLocalStorageKeysForReportAdministration = () => {
  Storage.local.remove(LOCAL_STORAGE_KEYS.BUILDING_SUBDIARY);
  Storage.local.remove(LOCAL_STORAGE_KEYS.SITE_CONSTRUCTOR);
  Storage.local.remove(LOCAL_STORAGE_KEYS.SITE_SUPERVISOR);
};

export const clearLocalStorageValueByKey = (key: string) => {
  Object.values(LOCAL_STORAGE_KEYS)
    .filter(localStorageKey => localStorageKey === key)
    .forEach(value => Storage.local.remove(value));
};
