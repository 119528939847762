import React from 'react';
import { connect } from 'react-redux';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import CustomRedirect from 'app/shared/util/custom-redirect';

const RoleSelection = (props: StateProps) => {
  if (props.hasPermission) {
    return <CustomRedirect to="/" />;
  } else {
    return <></>;
  }
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  hasPermission: !hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.NO_PERMISSION]),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(RoleSelection);
