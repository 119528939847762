import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { clearLocalStorage } from 'app/shared/util/local-storage-keys';
import { Storage } from 'react-jhipster';
import { AUTH_TOKEN_KEY, successLogin } from 'app/shared/reducers/authentication';
import jwt from 'jwt-decode';
import { Button, Modal, RadioButton } from 'app/shared/lib';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ChamberMember } from 'app/modules/ednevnik/chamber-member/chamber-member-model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { ACTION_TYPES } from 'app/shared/reducers/consent';

export interface IUserChamberMemberTypeSelectionModalProps extends StateProps {
  forceUserChamberMemberTypeSelection: boolean;
}

export const UserChamberMemberTypeSelectionModal = (props: IUserChamberMemberTypeSelectionModalProps) => {
  const [modal, setModal] = useState(props.forceUserChamberMemberTypeSelection);
  const [selectedUserChamberMemberType, setSelectedUserChamberMemberType] = useState(
    props.idUserChamberMember ? props.idUserChamberMember.toString() : undefined
  );
  const [userChamberMemberTypeOptions, setUserChamberMemberTypeOptions] = useState<ChamberMember[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    axios.get<ChamberMember[]>('/api/chamber-members/current-user').then(response => {
      const responseData = response.data;

      responseData.sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1));

      setUserChamberMemberTypeOptions(response.data);
    });
  }, []);

  useEffect(() => {
    if (props.forceUserChamberMemberTypeSelection) {
      setModal(true);
    }
  }, [modal]);

  const onUserChamberMemberTypeSelected = event => {
    setSelectedUserChamberMemberType(event.target.value);
  };

  const toggleModal = () => setModal(!modal);

  const changeUserChamberMemberType = () => {
    axios.post('api/authenticate/user-chamber-member-type/' + selectedUserChamberMemberType).then(response => {
      clearLocalStorage();
      const bearerToken = response.headers.authorization;
      const token = bearerToken.slice(7, bearerToken.length);
      Storage.local.set(AUTH_TOKEN_KEY, token);

      const user = jwt(token);
      dispatch(successLogin(user));
      toggleModal();

      if (props.idBuildingDiary) {
        history.push('/building-diaries/' + props.idBuildingDiary + '/diary-components');
      }

      window.location.reload();
    });
  };

  const isRadioButtonChecked = item => {
    if (selectedUserChamberMemberType) {
      return selectedUserChamberMemberType === item;
    }

    return false;
  };

  const getDisplayValue = (item: ChamberMember) => {
    if (item.active) {
      return item.fullAuthNumber;
    } else {
      return item.fullAuthNumber + ' (neaktivno)';
    }
  };

  const confirmButtonDisabled = () => {
    return selectedUserChamberMemberType === undefined;
  };

  return (
    <div className="mr-1 ml-1">
      <Button color="outline-primary" label="Odabir ovlaštenja" onClick={toggleModal} />
      <Modal backdrop="static" isOpen={modal} toggle={toggleModal} centered={true}>
        {!props.forceUserChamberMemberTypeSelection && <ModalHeader toggle={toggleModal}>Odaberite šifru ovlaštenja</ModalHeader>}
        {props.forceUserChamberMemberTypeSelection && <ModalHeader>Odaberite šifru ovlaštenja</ModalHeader>}
        <ModalBody>
          Odaberite šifru ovlaštenja s kojom se želite nastaviti rad u sustavu eGrađevinski dnevnik:
          <p />
          <div className="container">
            {userChamberMemberTypeOptions.map(item => (
              <div key={item.idChamberMember}>
                <RadioButton
                  value={item.idChamberMember}
                  label={getDisplayValue(item)}
                  onChange={onUserChamberMemberTypeSelected}
                  checked={isRadioButtonChecked(item.idChamberMember.toString())}
                />
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            label="Nastavi"
            color="info"
            appendIcon="las la-arrow-right"
            onClick={changeUserChamberMemberType}
            disabled={confirmButtonDisabled()}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = storeState => ({
  idUserChamberMember: storeState.authentication.account?.userChamberMemberId,
  isSupervisor: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.SYSTEM_SUPERVISOR]),
  isParticipant: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ROLE_CHAMBER_MEMBER]),
  shouldSeeConsentModal: storeState.authentication.account.shouldSeeConsentModal,
  idBuildingDiary: storeState.buildingDiary?.idBuildingDiary,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(UserChamberMemberTypeSelectionModal);
