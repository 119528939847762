import './label.scss';

import React from 'react';
import { Label as RsLabel, LabelProps as RsLabelProps, UncontrolledTooltip } from 'reactstrap';
import { uniqueId } from 'lodash';

interface LabelProps extends RsLabelProps {
  labelText: string;
  infoText?: string;
}

export const Label = ({ className, labelText, infoText, ...props }: LabelProps) => {
  const id = uniqueId('label-hint-');

  return (
    <div className={`${className} label`}>
      <RsLabel {...props}>{labelText}</RsLabel>
      {infoText && infoText.length ? (
        <>
          <div id={id} className="hint-icon-wrap">
            <i className="las la-question-circle" />
          </div>
          <UncontrolledTooltip target={id}>{infoText}</UncontrolledTooltip>
        </>
      ) : null}
    </div>
  );
};
