import './textarea.scss';

import React, { PropsWithChildren } from 'react';
import { Input as RsInput, InputProps as RsInputProps, InputGroup as RsInputGroup } from 'reactstrap';
import { declassify } from '../../../util';

export interface TextAreaProps extends RsInputProps {
  error?: string;
}

export const TextArea = ({ children, error = '', ...props }: PropsWithChildren<TextAreaProps>) => {
  const errorFeedback = <>{error && error.length ? <div className="invalid-feedback order-last">{error}</div> : null}</>;

  return (
    <RsInputGroup className={declassify({ 'has-validation': !!(error && error.length) })}>
      <RsInput type="textarea" invalid={!!(error && error.length)} {...props}>
        {children}
      </RsInput>
      {errorFeedback}
    </RsInputGroup>
  );
};
