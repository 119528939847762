import React from 'react';
import { noop, uniqueId } from 'lodash';
import { declassify } from 'app/shared/lib/util';
import { AsyncTypeahead, AsyncTypeaheadProps, TypeaheadModel } from 'react-bootstrap-typeahead';

import '../predictive-input/predictive-input.scss';

export interface PredictiveInputAsyncProps<T extends TypeaheadModel> extends Omit<AsyncTypeaheadProps<T>, 'onSearch' | 'isLoading'> {
  prependIcon?: string;
  isLoading?: boolean;
  onSearch?: (query: string) => void;
  className?: string;
  clearSearchFieldOnBlur?: boolean;
}

function PredictiveInputAsyncPureWrapper(
  {
    id = uniqueId(),
    selected,
    isLoading = false,
    onSearch = noop,
    className = '',
    placeholder = 'Search...',
    prependIcon = 'las la-search',
    multiple = false,
    minLength = 0,
    ...props
  }: PredictiveInputAsyncProps<any>,
  ref: React.LegacyRef<AsyncTypeahead<any>>
) {
  return (
    <AsyncTypeahead
      id={id + `-dropdown-predictive`}
      isLoading={isLoading}
      className={declassify(selected && selected.length ? 'rbt-selected' : '', className)}
      selected={selected}
      onSearch={onSearch}
      multiple={multiple}
      minLength={minLength}
      placeholder={placeholder}
      ref={ref}
      {...props}
    >
      {!(selected?.length && multiple) ? (
        <span className="predictive-prepend-icon">
          <i className={prependIcon}></i>
        </span>
      ) : null}
    </AsyncTypeahead>
  );
}

export const PredictiveInputAsyncPure = React.forwardRef(PredictiveInputAsyncPureWrapper);
