import axios from 'axios';
import { Storage } from 'react-jhipster';

import { SERVER_API_URL, SESSION_EXPIRED_MESSAGE } from 'app/config/constants';
import { toast } from 'react-toastify';
import { showToast } from 'app/shared/reducers/toast.util';

const TIMEOUT = 5 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    const token = Storage.local.get('authenticationToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      if (status === 401 && err.response.data.message === 'USER_DONT_HAVE_PERMISSION') {
        location.href = '/building-diaries';
        return Promise.reject({ ...err, message: 'Korisnik nema prava pregledati građevinski dnevnik' });
      } else {
        onUnauthenticated();
        showToast(toast.error, SESSION_EXPIRED_MESSAGE);
        return Promise.reject({ ...err, message: err.response.data.errorMessage });
      }
    } else if (status === 400) {
      if (err.response.data.errorCode) {
        return Promise.reject({ ...err, code: err.response.data.errorCode, message: err.response.data.errorMessage });
      }

      if (err.response.data.errorType) {
        return Promise.reject({ ...err, type: err.response.data.errorType, message: err.response.data.message });
      }

      return Promise.reject({ ...err, message: err.response.data.message });
    }

    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
