import './pagination.scss';

import React from 'react';
import { declassify, range } from '../../../util';
import { Select } from '..';

interface PaginationProps {
  page: number;
  size: number;
  total: number;
  sizeOptions?: number[];
  onChange: (page: number) => void;
  onSizeChange: (size: number) => void;
  className?: string;
}

export const Pagination = ({ page, size, total, sizeOptions = [], onChange, onSizeChange, className = '' }: PaginationProps) => {
  const maxPages = Math.ceil(total / size);
  if (page > maxPages) {
    page = 1;
    onChange(1);
  }
  const changePage = (p: number) => (ev: any) => {
    ev.preventDefault();
    onChange(p);
  };

  const getContent = () => {
    if (maxPages < 10) {
      return range(1, maxPages).map(v => (
        <li key={v} className={declassify('page-item', { active: page === v })}>
          <a className="page-link" href="#" onClick={changePage(v)}>
            {v}
          </a>
        </li>
      ));
    }
    if (page < 5) {
      return range(1, 5).map(v => (
        <li key={v} className={declassify('page-item', { active: page === v })}>
          <a className="page-link" href="#" onClick={changePage(v)}>
            {v}
          </a>
        </li>
      ));
    }
    if (page > maxPages - 4) {
      return range(maxPages - 4, maxPages).map(v => (
        <li key={v} className={declassify('page-item', { active: page === v })}>
          <a className="page-link" href="#" onClick={changePage(v)}>
            {v}
          </a>
        </li>
      ));
    }
    return range(page - 2, page + 2).map(v => (
      <li key={v} className={declassify('page-item', { active: page === v })}>
        <a className="page-link" href="#" onClick={changePage(v)}>
          {v}
        </a>
      </li>
    ));
  };

  const getFirstPage = () => {
    if (maxPages >= 10 && page > 4) {
      return (
        <>
          <li key={1} className={declassify('page-item', { active: page === 1 })}>
            <a className="page-link" href="#" onClick={changePage(1)}>
              {1}
            </a>
          </li>
          <li key={'first'} className="page-item disabled">
            <a className="page-link">{'...'}</a>
          </li>
        </>
      );
    }
  };

  const getLastPage = () => {
    if (maxPages >= 10 && page <= maxPages - 4) {
      return (
        <>
          <li key={'last'} className="page-item disabled">
            <a className="page-link">{'...'}</a>
          </li>
          <li key={maxPages} className={declassify('page-item', { active: page === maxPages })}>
            <a className="page-link" href="#" onClick={changePage(maxPages)}>
              {maxPages}
            </a>
          </li>
        </>
      );
    }
  };

  return (
    <div className={declassify(className, 'pagination-wrapper')}>
      {sizeOptions?.length > 0 ? (
        <div className="pagination-size">
          <Select
            selected={size.toString()}
            onChange={v => onSizeChange(parseInt(v, 10))}
            options={sizeOptions
              ?.map(v => v.toString())
              .map(value => {
                return { value };
              })}
          />
        </div>
      ) : (
        <div />
      )}

      <nav className="pagination-nav">
        <ul className="pagination">
          <li className={declassify('page-item', { disabled: page === 1 })}>
            <a className="page-link" href="#" onClick={changePage(page - 1)}>
              <i className="las la-caret-left" />
            </a>
          </li>
          {getFirstPage()}
          {getContent()}
          {getLastPage()}
          <li className={declassify('page-item', { disabled: page === maxPages })}>
            <a className="page-link" href="#" onClick={changePage(page + 1)}>
              <i className="las la-caret-right" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};
