import './login.scss';

import React, { useEffect } from 'react';
import { initKeycloak, useKeycloak } from 'app/modules/keycloak';
import { connect, useDispatch } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { downloadFileFromBase64 } from 'app/shared/util/file-utils';
import { showToast } from 'app/shared/reducers/toast.util';
import { toast } from 'react-toastify';
import { messages } from 'app/config/constants';
import useWindowDimensions from 'app/shared/hooks/useWindowDimensions';

export const LoginPage = (props: StateProps) => {
  const { height, width } = useWindowDimensions();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    initKeycloak(dispatch);
  }, []);

  const handleNiasLogin = () => {
    history.push('/login');
    if (props.autoRedirectToNias) {
      useKeycloak()?.login({ idpHint: 'saml-nias' });
    } else {
      useKeycloak()?.login();
    }
  };

  const downloadLoginUserManual = () => {
    axios
      .get('/api/user-manuals/login')
      .then(response => {
        const data = response.data;
        const applicationType = data.fileType.type + '/' + data.fileType.subtype;
        downloadFileFromBase64(data.fileName, data.contentData, applicationType);
      })
      .catch(error => {
        showToast(toast.error, messages.FETCHING_DOCUMENT_ERROR);
      });
  };

  return (
    <div className="h-100">
      <div id="login-page" className="d-flex h-100 flex-wrap align-items-center justify-content-center col-12">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center order-1 order-md-0 order-lg-0 order-xl-0">
          <div className="my-5">
            <h6>MINISTARSTVO PROSTORNOGA UREĐENJA, </h6>
            <h6>GRADITELJSTVA I DRŽAVNE IMOVINE</h6>
            <br />
            <h4>Dobrodošli na eGrađevinski dnevnik</h4>
            <br />
            <p>Ova usluga omogućava kreiranje i vođenje eGrađevinskog dnevnika.</p>
          </div>
          <br />
          <div className="my-1 login-button-wrapper d-flex justify-content-center">
            <a className="go_register" onClick={handleNiasLogin}>
              <img src="content/images/login-button.svg" alt="Prijava" />
              Prijava
            </a>
          </div>
        </div>
        <div className="d-flex justify-content-center col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <img className={`resized-img ${width < 768 && 'w-75'}`} src="content/images/frontpage.png" />
        </div>
      </div>
      <div className="login-user-manuals">
        <a onClick={downloadLoginUserManual}>Upute za prijavu</a>
      </div>
    </div>
  );
};

const mapStateToProps = ({ keycloak }: IRootState) => ({
  autoRedirectToNias: keycloak.autoRedirectToNias,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(LoginPage);
