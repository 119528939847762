import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { SESSION_EXPIRED_MESSAGE } from 'app/config/constants';

export function showToast(toastType: (content: ToastContent, options?: ToastOptions | undefined) => React.ReactText, message: string) {
  if (!toast.isActive(window.btoa(encodeURIComponent(message)))) {
    if (toast.isActive(window.btoa(encodeURIComponent(SESSION_EXPIRED_MESSAGE)))) {
      return;
    }
    const toastId = window.btoa(encodeURIComponent(message));
    toastType(message, {
      position: toast.POSITION.TOP_RIGHT,
      toastId,
    });
  }
}
