import { SUCCESS } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  SET_NOTIFICATIONS: 'notificationsState/SET_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS_COUNT: 'notificationState/UPDATE_NOTIFICATIONS_COUNT',
};

const initialState: any = {
  notificationsShown: false,
  notificationsCount: 0,
};

export type NotificationsState = Readonly<typeof initialState>;

export default (state: NotificationsState = initialState, action): NotificationsState => {
  switch (action.type) {
    case ACTION_TYPES.SET_NOTIFICATIONS: {
      return {
        ...state,
        notificationsShown: action.shown,
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_NOTIFICATIONS_COUNT): {
      return {
        ...state,
        notificationsCount: action.payload.data,
      };
    }
    case ACTION_TYPES.UPDATE_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        notificationsCount: action.payload,
      };
    }
    default:
      return state;
  }
};
