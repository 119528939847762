import './home.scss';

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { ACTION_TYPES } from 'app/shared/reducers/consent';
import CustomRedirect from 'app/shared/util/custom-redirect';

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { isAuthenticated, isAdmin } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.shouldSeeConsentModal && props.hasPermission && !props.canSelectRole) {
      dispatch({
        type: ACTION_TYPES.SET_SHOW_CONSENT_MODAL,
        payload: { showConsentModal: true },
      });
    }
  }, [props.shouldSeeConsentModal]);

  if (isAuthenticated) {
    if (isAdmin) {
      return <CustomRedirect to="/admin/health" />;
    } else {
      if (!props.hasPermission && props.canSelectRole) {
        return <CustomRedirect to="/role-selection" />;
      } else if (
        props.hasPermission &&
        props.isChamberMember &&
        props.userChamberMemberTypeNotSelected &&
        props.hasMultipleUserChamberMembers
      ) {
        return <CustomRedirect to="/user-chamber-member-selection" />;
      } else {
        return <CustomRedirect to="/building-diaries" />;
      }
    }
  }

  return <CustomRedirect to="/login" />;
};

const mapStateToProps = storeState => ({
  isAuthenticated: storeState.authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ADMIN]),
  canSelectRole: storeState.authentication.account.canSelectRole,
  hasPermission: !hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.NO_PERMISSION]),
  shouldSeeConsentModal: storeState.authentication.account.shouldSeeConsentModal,
  userChamberMemberTypeNotSelected: storeState.authentication.account?.userChamberMemberId === undefined,
  hasMultipleUserChamberMembers: storeState.authentication.account?.hasMultipleUserChamberMembers,
  isChamberMember: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ROLE_CHAMBER_MEMBER]),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
