export const ACTION_TYPES = {
  CHAMBER_MEMBER_ACTIVITY_CHANGED: 'CanUserCreateBuildingDiaryState/CHAMBER_MEMBER_ACTIVITY_CHANGED',
};

const initialState = false;

export type CanUserCreateBuildingDiaryState = Readonly<typeof initialState>;

export default (state: CanUserCreateBuildingDiaryState = initialState, action): CanUserCreateBuildingDiaryState => {
  switch (action.type) {
    case ACTION_TYPES.CHAMBER_MEMBER_ACTIVITY_CHANGED: {
      return action.payload;
    }
    default:
      return state;
  }
};
