import React from 'react';
import {
  Dropdown as RsDropdown,
  DropdownItem as RsDropdownItem,
  DropdownItemProps as RsDropdownItemProps,
  DropdownMenu as RsDropdownMenu,
  DropdownProps as RsDropdownProps,
  DropdownToggle as RsDropdownToggle,
} from 'reactstrap';

import './dropdown.scss';

interface DropdownProps extends RsDropdownProps {
  toggleLabel?: string;
  children: { label: string; header?: boolean; divider?: boolean }[];
  onItemSelect: (item: string) => void;
}

export const Dropdown = ({
  toggleLabel = 'Select',
  children,
  className = '',
  selected,
  onItemSelect,
  disabled,
  onRemove,
  menuDirection = 'left',
  isOpen,
  ...props
}: DropdownProps) => {
  return (
    <RsDropdown {...props} disabled={disabled ?? false} className={className} isOpen={isOpen}>
      <RsDropdownToggle className={disabled ? 'disabled' : ''}>
        {selected ? <div className="dropdown-toggle-item">{selected}</div> : <div className="dropdown-toggle-item">{toggleLabel}</div>}
        {selected ? (
          <button type="button" className="close" aria-label="Close" onClick={onRemove}></button>
        ) : (
          <i className={isOpen ? 'las la-chevron-circle-up' : 'las la-chevron-circle-down'} />
        )}
      </RsDropdownToggle>
      <RsDropdownMenu right={menuDirection === 'right'}>
        {children.map((item, idx) => (
          <RsDropdownItem header={item.header ?? false} divider={item.divider ?? false} key={idx} onClick={() => onItemSelect(item.label)}>
            {item.label}
          </RsDropdownItem>
        ))}
      </RsDropdownMenu>
    </RsDropdown>
  );
};
