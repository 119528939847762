import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Home from 'app/modules/home/home';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import RoleSelection from 'app/modules/ednevnik/role-selection/role-selection';
import CustomLogin from 'app/modules/login/custom-login';
import Logout from 'app/modules/logout/logout';
import UserChamberMemberSelection from 'app/modules/ednevnik/user-chamber-member-selection/user-chamber-member-selection';
import NoPermissionComponent from 'app/shared/auth/no-permission-component';

const Account = Loadable({
  loading: () => <></>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <></>,
});

const BuildingDiaries = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/ednevnik/building-diaries'),
  loading: () => <></>,
});

const Routes = () => {
  return (
    <div className="view-routes">
      <Switch>
        <ErrorBoundaryRoute path="/custom-login" component={CustomLogin} />
        <ErrorBoundaryRoute path="/login" component={Login} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <PrivateRoute
          path="/building-diaries"
          component={BuildingDiaries}
          hasAnyAuthorities={[AUTHORITIES.SYSTEM_SUPERVISOR, AUTHORITIES.ROLE_CHAMBER_MEMBER, AUTHORITIES.NOT_CHAMBER_MEMBER]}
        />
        <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute
          path="/account"
          component={Account}
          hasAnyAuthorities={[
            AUTHORITIES.ADMIN,
            AUTHORITIES.SYSTEM_SUPERVISOR,
            AUTHORITIES.ROLE_CHAMBER_MEMBER,
            AUTHORITIES.NOT_CHAMBER_MEMBER,
          ]}
        />
        <ErrorBoundaryRoute path="/role-selection" exact component={RoleSelection} />
        <ErrorBoundaryRoute path="/user-chamber-member-selection" exact component={UserChamberMemberSelection} />
        <ErrorBoundaryRoute path="/no-permission" exact component={NoPermissionComponent} />
        <ErrorBoundaryRoute path="/" exact component={Home} />
        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
