import './activities.scss';

import React, { PropsWithChildren } from 'react';
import { declassify } from '../../../util';

interface ActivitiesProps {
  isOpen: boolean;
  onClose: () => void;
  removeFooterOverflow?: boolean;
  className?: string;
}

export const Activities = ({
  isOpen,
  onClose,
  removeFooterOverflow = false,
  children,
  className = '',
}: PropsWithChildren<ActivitiesProps>) => {
  return (
    <div className={declassify('activities', { open: isOpen }, { 'remove-footer-overflow': removeFooterOverflow }, className)}>
      <div className="activities-header">
        <span className="activities-title">Obavijesti</span>
        <div className="activities-close" onClick={onClose}>
          <i className="las la-times" />
        </div>
      </div>
      <div className="activities-content">{children}</div>
    </div>
  );
};
