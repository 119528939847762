import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initKeycloak, useKeycloak } from 'app/modules/keycloak';
import { Button } from 'app/shared/lib';
import useWindowDimensions from 'app/shared/hooks/useWindowDimensions';
import './login.scss';

export const CustomLoginPage = () => {
  const { height, width } = useWindowDimensions();

  const dispatch = useDispatch();

  useEffect(() => {
    initKeycloak(dispatch);
  }, []);

  const handleNiasLogin = () => {
    useKeycloak()?.login();
  };

  return (
    <div id="login-page" className="d-flex flex-wrap align-items-center justify-content-center col-12">
      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center order-1 order-md-0 order-lg-0 order-xl-0">
        <div className="my-5">
          <h6>MINISTARSTVO PROSTORNOGA UREĐENJA, </h6>
          <h6>GRADITELJSTVA I DRŽAVNE IMOVINE</h6>
          <br />
          <h4>Dobrodošli na eGrađevinski dnevnik</h4>
          <br />
          <p>Ova stranica omogućava posebno prijavljivanje u sustav.</p>
        </div>
        <Button
          size="lg"
          label="Prijavite se"
          appendIcon="las la-arrow-right"
          color="info"
          onClick={handleNiasLogin}
          className="order-0 order-md-1 order-lg-1 order-xl-1"
          data-cy="submit"
        />
      </div>
      <div className="d-flex justify-content-center col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <img className={`resized-img ${width < 768 && 'w-75'}`} src="content/images/frontpage.png" />
      </div>
    </div>
  );
};

export default CustomLoginPage;
