export const downloadFileFromBase64 = (fileName: string, base64Contend: string, applicationType: string) => {
  downloadFileFromBytes(fileName, base64ToArrayBuffer(base64Contend), applicationType);
};
export const downloadFileFromBytes = (fileName: string, bytes: Uint8Array, applicationType: string) => {
  const blob = new Blob([bytes], { type: applicationType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};
export const fileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(('' + reader.result).split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}
