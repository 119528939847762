import { Moment } from 'moment';
import React from 'react';
import Datetime, { DatetimepickerProps } from 'react-datetime';
import { Input, InputProps } from '..';

interface DatepickerProps extends Omit<DatetimepickerProps, 'timeFormat' | 'input'> {
  inputProps?: InputProps;
  maxDate?: Moment;
  minDate?: Moment;
}

export const Datepicker = ({ inputProps, minDate, maxDate, ...props }: DatepickerProps) => {
  const renderInput = (iProps, _openCalendar, _closeCalendar) => {
    return <Input {...iProps} addonAppend={<i className="las la-calendar" />} />;
  };

  const isValid = (currentDate: Moment, _selectedDate: Moment) => {
    if (maxDate && minDate) {
      return currentDate.isSameOrAfter(minDate, 'day') && currentDate.isSameOrBefore(maxDate, 'day');
    } else if (maxDate && !minDate) {
      return currentDate.isSameOrBefore(maxDate, 'day');
    } else if (!maxDate && minDate) {
      return currentDate.isSameOrAfter(minDate, 'day');
    } else {
      return true;
    }
  };

  return (
    <Datetime
      input={true}
      timeFormat={false}
      locale="hr"
      renderInput={renderInput}
      inputProps={inputProps}
      isValidDate={isValid}
      {...props}
    />
  );
};
