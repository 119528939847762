import React, { PropsWithChildren } from 'react';

export const TabHeader = ({ children }: PropsWithChildren<Record<never, any>>) => {
  return (
    <div className="tab-header">
      {children}
      <span className="tab-header-filler" />
    </div>
  );
};
