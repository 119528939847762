import CustomRedirect from 'app/shared/util/custom-redirect';
import { IRootState } from 'app/shared/reducers';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { connect } from 'react-redux';
import React from 'react';

const UserChamberMemberSelection = (props: StateProps) => {
  if (props.hasPermission) {
    if (!props.userChamberMemberTypeNotSelected || props.hasRoleNotChamberMember) {
      return <CustomRedirect to="/" />;
    }
  } else {
    return <CustomRedirect to="no-permission" />;
  }

  return <></>;
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  hasPermission: !hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.NO_PERMISSION]),
  hasRoleNotChamberMember: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.NOT_CHAMBER_MEMBER]),
  userChamberMemberTypeNotSelected: authentication.account?.userChamberMemberId === undefined,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(UserChamberMemberSelection);
