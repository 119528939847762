const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  ROLE_CHAMBER_MEMBER: 'ROLE_CHAMBER_MEMBER',
  SYSTEM_SUPERVISOR: 'ROLE_SYSTEM_SUPERVISOR',
  NOT_CHAMBER_MEMBER: 'ROLE_NOT_CHAMBER_MEMBER',
  NO_PERMISSION: 'ROLE_NO_PERMISSION',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
  DATA_SUCCESSFULLY_SAVED: 'Podaci su uspješno spremljeni.',
  DATA_SUCCESSFULLY_DELETED: 'Podaci su uspješno obrisani.',
  DATA_SUCCESSFULLY_FETCHED: 'Podaci su uspješno dohvaćeni.',
  DATA_SUCCESSFULLY_COPIED: 'Podaci su uspješno kopirani.',
  DOCUMENT_SUCCESSFULLY_SIGNED: 'Datoteka je uspješno potpisana.',
  DIARY_SUCCESSFULLY_CREATED: 'Građevinski dnevnik je uspješno kreiran.',
  CONSENT_APPROVED_FOR_PARTICIPATING_IN_BUILD: 'Uspješno ste potvrdili suglasnost za sudjelovanje u gradnji.',
  REPORT_GENERATING_SUCCESSFULLY_STARTED:
    'Kreiranje izvještaja je uspješno pokrenuto. Zaprimit ćete obavijest nakon što datoteka za potpisivanje bude spremna.',
  CONSTRUCTION_SITE_REPORT_GENERATING_SUCCESSFULLY_STARTED:
    'Kreiranje izvještaja je uspješno pokrenuto. Zaprimit ćete obavijest nakon što datoteka bude spremna.',
  SUBDIARY_ABORTED_SUCCESSFULLY: 'Dio građevinskog dnevnika je uspješno poništen.',
  SUBDIARY_CLOSED_SUCCESSFULLY:
    'Dio građevinskog dnevnika je uspješno zatvoren. \n' + 'Zaprimit ćete obavijest nakon što datoteka za potpisivanje bude spremna.',
  DIARY_CLOSED_SUCCESSFULLY:
    'Građevinski dnevnik je uspješno zatvoren. \n' + 'Zaprimit ćete obavijest nakon što datoteka za potpisivanje bude spremna.',
  FETCHING_ERROR: 'Došlo je do pogreške prilikom dohvata podataka.',
  FETCHING_DOCUMENT_ERROR: 'Došlo je do pogreške prilikom preuzimanja dokumenta. Pokušajte ponovno.',
  SAVING_ERROR: 'Došlo je do pogreške prilikom spremanja podataka. Pokušajte ponovno.',
  DELETING_ERROR: 'Došlo je do pogreške prilikom brisanja podataka. Pokušajte ponovno.',
  REFRESHING_ERROR: 'Došlo je do pogreške prilikom osvježavanja podataka. Pokušajte ponovno.',
  REPORT_CREATING_ERROR: 'Došlo je do pogreške prilikom kreiranja izvještaja. Pokušajte ponovno.',
  REPORT_FETCHING_ERROR: 'Došlo je do pogreške prilikom dohvata podataka završenih izvještaja.',
  DOCUMENT_OPENING_ERROR: 'Došlo je do pogreške prilikom otvaranja datoteke. Pokušajte ponovno.',
  NOTIFICATION_FETCH_ERROR: 'Došlo je do pogreške prilikom dohvata notifikacija. Pokušajte ponovno.',
  ABORTING_SUBDIARY_ERROR: 'Došlo je do pogreške prilikom poništavanja dijela građevinskog dnevnika. Pokušajte ponovno.',
  CLOSING_SUBDIARY_ERROR: 'Došlo je do pogreške prilikom zatvaranja dijela građevinskog dnevnika. Pokušajte ponovno.',
  CLOSING_DIARY_ERROR: 'Došlo je do pogreške prilikom zatvaranja građevinskog dnevnika. Pokušajte ponovno.',
  SIGNING_ERROR: 'Došlo je do pogreške prilikom potpisivanja. Pokušajte ponovno.',
  ERROR_ON_RECALL: 'Došlo je do pogreške prilikom opoziva. Pokušajte ponovno.',
  ERROR_ON_APPROVAL: 'Došlo je do pogreške prilikom potvrde. Pokušajte ponovno.',
  FINISHING_WORK_LOG_ERROR: 'Došlo je do pogreške prilikom završetka unosa. Pokušajte ponovno.',
  NO_SAVED_DATA_PREVIOUS_DAY: 'Za prethodni dan nema spremljenih podataka.',
  ERROR_OIB_NOT_VALID: 'Uneseni OIB je neispravan!',
  FINALIZE_DEADLINE_EXCEEDED: 'Istekao je rok za završetak unosa!',
  BUILDING_PERMIT_WRONG_TYPE_ERROR: 'Nije moguće preuzeti podatke predmeta jer je vrsta predmeta neispravna!',
  ENTERED_CONSTRUCTION_PARTICIPANT_ALREADY_EXISTS: 'Uneseni sudionik u gradnji je već dodan u istoj ulozi!',
  CONSENT_NOT_APPROVED: 'Niste potvrdili suglasnost. Svoju suglasnost možete naknadno potvrditi unutar pregleda obavijesti.',
  NO_RESULTS_FOR_ENTERED_PARAMETERS: 'Prema unesenim parametrima nisu pronađeni rezultati!',
  PERMIT_DOESNT_EXIST_IN_EDOZVOLA: 'Uneseni predmet ne postoji u sustavu eDozvola.',
  CANT_FETCH_DATA_BECAUSE_PERMIT_STATUS_IS_NOT_VALID: 'Nije moguće preuzeti podatke predmeta jer je status predmeta neispravan.',
  BUILDING_DIARY_PERMIT_TYPE_IS_NOT_VALID: 'Tip predmeta građevinskog dnevnika nije ispravan.',
  ENTERED_PERMIT_DOESNT_CONTAIN_NEEDED_DATA: 'Uneseni predmet ne sadrži potrebne podatke o lokaciji, rješavatelju ili surješavatelju.',
  CHAMBER_MEMBER_NOT_ACTIVE:
    'Vaše članstvo u komori je neaktivno ili vam je isteklo ovlaštenje! Nećete moći koristiti sve funkcionalnosti sustava eGrađevinski dnevnik.',
  WEB_ID_IS_MANDATORY: 'Identifikacijska oznaka je obavezna.',
  WEB_ID_FORMAT_NOT_VALID: 'Identifikacijska oznaka nije ispravnog formata.',
  INVESTOR_IS_MANDATORY: 'Na građevinskom dnevniku mora biti barem jedan aktivan investitor!',
  REPRESENTATIVE_AND_INVESTOR_CANT_BE_SAME_PERSON: 'Zastupnik i investitor ne mogu biti iste osobe!',
  REQUIRED_SUPERVISING_DOCUMENT: 'Potrebno je dodati dokument - ugovor o nadzoru!',
  MINIMUM_ONE_DOCUMENT_IS_REQUIRED: 'Potrebno je priložiti minimalno jedan dokument!',
  DOCUMENT_TEMPLATE_DOES_NOT_EXIST: 'Ne postoji definiran predložak za kreiranje dokumenta!',
  DOCUMENT_SIZE_LIMIT: 'Dokument koji se učitava ne smije biti veći od 20 MB.',
  IMPLEMENTATION_PROJECT_DOCUMENT_SIZE_LIMIT: 'Dokument koji se učitava ne smije biti veći od 100 MB.',
  DOCUMENT_FORMATS: 'Dokument koji se učitava mora biti u pdf, jpg, jpeg ili png formatu.',
  DOCUMENT_PDF_FORMAT: 'Dokument koji se učitava mora biti u pdf formatu.',
  AT_LEAST_ONE_PERMIT_WITH_SPECIFIC_DATA_NEEDED:
    'Potrebno je na građevinski dnevnik dodati barem jedan predmet kojim se odobrava građenje/uklanjanje koji sadrži projekt s minimalno jednom mapom.',
  THIS_SITE_SUPERVISOR_CANT_SUPERVISE_SELECTED_WORK_TYPE:
    'Postavljeni nadzorni inženjer ima oznaku strukovne pripadnosti koja ne može nadzirati odabranu vrstu radova!',
  CANT_SELECT_DUPLICATE_WORK_TYPE: 'Nije dozvoljeno dvaput odabrati istu vrstu radova!',
  ERROR_ON_FETCHING_REVIEW_DATA: 'Došlo je do pogreške prilikom dohvata podataka za pregled!',
  DELETE_SAVED_ENTRY: 'Jeste li sigurni da želite izbrisati spremljeni unos?',
  DELETE_OPTION: 'Obriši',
  COMPLETE_OPTION: 'Završi',
  CANCEL_OPTION: 'Odustani',
  WORK_LOG_HEADER_GROUP_UNLOCKED: 'Unos izvršenih radova je uspješno otključan.',
  WORK_LOG_HEADER_GROUP_LOCKED: 'Unos izvršenih radova je uspješno zaključan.',
  DIARY_DISABLED: 'Dnevnik je uspješno poništen.'
};

export const participantType = {
  ENGINEER: 1,
  SUPERVISING_ENGINEER: 2,
  WORK_MANAGER: 3,
};

export const APP_DATE_FORMAT = 'DD.MM.YYYY.';
export const APP_DATE_WITH_DAY_NAME_FORMAT = 'dddd, DD.MM.YYYY.';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const FILE_SIZE_LIMIT_IN_MEGABYTES = 20;

export const IMPLEMENTATION_PROJECT_FILE_SIZE_LIMIT = 100;
export const BYTES_IN_A_MEGABYTE = 1000000;
export const OIB_REGEX = /^[0-9]*$/;
export const SESSION_EXPIRED_MESSAGE = 'Odjavljeni ste zbog isteka sesije. Za nastavak rada potrebno se ponovno prijaviti.';
export const APP_TOAST_MESSAGE_DURATION = 10000;
