import React from 'react';

import { FormGroup, Input as RsInput, InputProps as RsInputProps } from 'reactstrap';
import { uniqueId } from 'lodash';
import './check.scss';

interface CheckProps extends RsInputProps {
  id?: string;
  label?: string;
}

export const Check = ({ label, id = uniqueId('checkbox-'), ...props }: CheckProps) => {
  return (
    <FormGroup check className="custom-checkbox">
      <RsInput type="checkbox" className="custom-control-input" id={id} {...props} />
      <label htmlFor={id} className="custom-control-label">
        {label ?? ''}
      </label>
    </FormGroup>
  );
};
