import React, { useEffect } from 'react';
import { Loader } from 'app/shared/loader/loader';
import { fetchAndLogoutKeycloak } from 'app/modules/keycloak';
import { useHistory } from 'react-router-dom';

export const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    fetchAndLogoutKeycloak();

    setTimeout(() => {
      if (history.location.pathname === '/logout') {
        history.replace('/login');
      }
    }, 5000);
  }, []);

  return <Loader />;
};

export default Logout;
