import { SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { BuildingSubdiary } from 'app/modules/ednevnik/building-subdiaries/model/building-subdiaries.model';

export const ACTION_TYPES = {
  GET_BUILDING_SUBDIARY_DATA: 'BuildingSubdiaryState/GET_BUILDING_SUBDIARY_DATA',
  STORE_BUILDING_SUBDIARY_DATA: 'BuildingSubdiaryState/STORE_BUILDING_SUBDIARY_DATA',
  RESET_BUILDING_SUBDIARY_DATA: 'BuildingSubdiaryState/RESET_BUILDING_SUBDIARY_DATA',
};

const initialState: BuildingSubdiary = {
  idBuildingDiary: null,
  idBuildingSubdiary: null,
  status: null,
  code: null,
  idSiteConstructor: null,
  siteConstructor: null,
  constructorWork: null,
  constructorWorkAdditional: null,
  supervisingEngineer: null,
  workManager: null,
  subStatus: null,
  creationDate: null,
  canCurrentUserSeeBuildingSubdiary: false,
  documentGeneratorTesting: false,
  documentLoading: false,
  dailyLogId: null,
};

export type BuildingSubdiaryState = Readonly<typeof initialState>;

export default (state: BuildingSubdiaryState = initialState, action): BuildingSubdiaryState => {
  switch (action.type) {
    case SUCCESS(ACTION_TYPES.GET_BUILDING_SUBDIARY_DATA): {
      const newDataObject = Object.assign(state, { ...action.payload });
      return {
        ...newDataObject,
      };
    }
    case ACTION_TYPES.STORE_BUILDING_SUBDIARY_DATA: {
      const newDataObject = Object.assign(state, { ...action.payload });
      return {
        ...newDataObject,
      };
    }
    case ACTION_TYPES.RESET_BUILDING_SUBDIARY_DATA: {
      return {
        ...initialState,
        code: null,
      };
    }
    default:
      return state;
  }
};

export const getBuildingSubdiaryData = idBuildingSubdiary => ({
  type: ACTION_TYPES.GET_BUILDING_SUBDIARY_DATA,
  payload: axios.get(`/api/building-subdiaries/${idBuildingSubdiary}`),
});
