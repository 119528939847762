import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import buildingDiary, { BuildingDiaryState } from 'app/shared/reducers/buildingdiary';
import consent, { ShowConsentModalState } from 'app/shared/reducers/consent';
import buildingSubdiary, { BuildingSubdiaryState } from 'app/shared/reducers/buildingsubdiary';
import globalNotificationState, { GlobalNotificationState } from 'app/shared/reducers/global-notification';
import screenSizeState, { ScreenSizeState } from 'app/shared/reducers/screen-size';
import keycloak, { KeycloakState } from 'app/shared/reducers/keycloak';
import notificationsState, { NotificationsState } from 'app/shared/reducers/notifications';
import canUserCreateBuildingDiaryState, { CanUserCreateBuildingDiaryState } from 'app/shared/reducers/can-user-create-building-diary';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly buildingDiary: BuildingDiaryState;
  readonly buildingSubdiary: BuildingSubdiaryState;
  readonly keycloak: KeycloakState;
  readonly consent: ShowConsentModalState;
  readonly globalNotificationState: GlobalNotificationState;
  readonly notificationsState: NotificationsState;
  readonly screenSizeState: ScreenSizeState;
  readonly canUserCreateBuildingDiaryState: CanUserCreateBuildingDiaryState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  buildingDiary,
  buildingSubdiary,
  globalNotificationState,
  notificationsState,
  screenSizeState,
  canUserCreateBuildingDiaryState,
  keycloak,
  consent,
});

export default rootReducer;
