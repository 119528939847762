export const ACTION_TYPES = {
    SET_AUTO_REDIRECT_TO_NIAS: 'KeycloakState/SET_AUTO_REDIRECT_TO_NIAS'
};

const initialState = {
    autoRedirectToNias: true
}

export type KeycloakState = Readonly<typeof initialState>;


export default (state: KeycloakState = initialState, action): KeycloakState => {
    switch (action.type) {
        case ACTION_TYPES.SET_AUTO_REDIRECT_TO_NIAS:
            return {
                ...state,
                autoRedirectToNias: action.payload.autoRedirectToNias
            };
        default:
            return initialState;
    }
}
