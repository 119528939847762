import React, { useEffect, useState } from 'react';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Button, Modal, RadioButton } from 'app/shared/lib';
import axios from 'axios';
import { Storage } from 'react-jhipster';
import { AUTH_TOKEN_KEY, successLogin } from 'app/shared/reducers/authentication';
import jwt from 'jwt-decode';
import { connect, useDispatch } from 'react-redux';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { clearLocalStorage } from 'app/shared/util/local-storage-keys';
import { ACTION_TYPES } from 'app/shared/reducers/consent';
import { useHistory } from 'react-router-dom';

export interface IRoleSelectionModalProps extends StateProps {
  hasMultipleRoles: boolean;
}

export const RoleSelectionModal = (props: IRoleSelectionModalProps) => {
  const SYSTEM_SUPERVISOR = '1';
  const CONSTRUCTION_SITE_PARTICIPANT = '2';
  const dispatch = useDispatch();
  const [modal, setModal] = useState(props.hasMultipleRoles);
  const history = useHistory();
  const toggleModal = () => setModal(!modal);
  const [selectedRole, setSelectedRole] = useState(SYSTEM_SUPERVISOR);

  useEffect(() => {
    if (props.hasMultipleRoles) {
      setModal(true);
    }

    if (props.isSupervisor) {
      setSelectedRole(SYSTEM_SUPERVISOR);
    } else if (props.isParticipant) {
      setSelectedRole(CONSTRUCTION_SITE_PARTICIPANT);
    }
  }, [modal]);

  const onRoleSelected = event => {
    setSelectedRole(event.target.value);
  };

  const setUserRole = () => {
    axios.post('api/authenticate/role/' + selectedRole).then(response => {
      clearLocalStorage();
      const bearerToken = response.headers.authorization;
      const token = bearerToken.slice(7, bearerToken.length);
      Storage.local.set(AUTH_TOKEN_KEY, token);

      const user = jwt(token);
      dispatch(successLogin(user));
      toggleModal();

      if (props.userChamberMemberTypeNotSelected) {
        history.push('/user-chamber-member-selection');
      }

      if (selectedRole === CONSTRUCTION_SITE_PARTICIPANT && props.shouldSeeConsentModal) {
        dispatch({
          type: ACTION_TYPES.SET_SHOW_CONSENT_MODAL,
          payload: { showConsentModal: true },
        });
      } else {
        history.push('/building-diaries');
      }
    });
  };

  return (
    <div className="mr-1 ml-1">
      <Button color="outline-primary" label="Odabir uloge" onClick={toggleModal} />
      <Modal backdrop="static" isOpen={modal} toggle={toggleModal} centered={true}>
        {!props.hasMultipleRoles && <ModalHeader toggle={toggleModal}>Odaberite vrstu prijave</ModalHeader>}
        {props.hasMultipleRoles && <ModalHeader>Odaberite vrstu prijave</ModalHeader>}
        <ModalBody>
          Odaberite ulogu s kojom se želite prijaviti u sustav eGrađevinski dnevnik:
          <p />
          <div className="container">
            <div className="row col-md-12">
              <RadioButton value="1" label="Nadzor sustava" onChange={onRoleSelected} checked={selectedRole.toString() === '1'} />
            </div>
            <div className="row col-md-12">
              <RadioButton value="2" label="Sudionik u gradnji" onChange={onRoleSelected} checked={selectedRole.toString() === '2'} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button label="Nastavi" color="info" appendIcon="las la-arrow-right" onClick={setUserRole} />
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = storeState => ({
  isSupervisor: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.SYSTEM_SUPERVISOR]),
  isParticipant: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ROLE_CHAMBER_MEMBER]),
  shouldSeeConsentModal: storeState.authentication.account.shouldSeeConsentModal,
  userChamberMemberTypeNotSelected: storeState.authentication.account?.userChamberMemberId === undefined,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(RoleSelectionModal);
