import './download.scss';

import React from 'react';
import { declassify } from '../../../util';

interface DownloadProps {
  downloadLink: string;
  fileName: string;
  className?: string;
}

export const Download = ({ fileName, downloadLink, className = '' }: DownloadProps) => {
  return (
    <div className={declassify('download', className)}>
      <div className="file-name-wrap">
        <span className="file-name">{fileName}</span>
      </div>
      <a className="download-link" href={downloadLink} download={true}>
        <i className="las la-file-download"></i>
      </a>
    </div>
  );
};
