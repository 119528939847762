import './breadcrumbs.scss';

import React from 'react';
import { declassify } from 'app/shared/lib/util';

interface BreadcrumbItem {
  title: string;
  path: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  className?: string;
}

export const Breadcrumbs = ({ items, className = '' }: BreadcrumbsProps) => {
  return (
    <div className={declassify('breadcrumb-wrap', className)}>
      <ul className="breadcrumbs">
        {items.map((item, i) => (
          <li key={i} className="breadcrumb-item">
            <a href={item.path}>{item.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};
