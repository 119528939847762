import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import './custom-toastify.scss';
import 'app/config/dayjs.ts';

import React, { useEffect, useLayoutEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';
import { setLocale } from 'app/shared/reducers/locale';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { APP_TOAST_MESSAGE_DURATION, AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import Footer from 'app/shared/layout/footer/footer';
import { Storage } from 'react-jhipster';
import { AUTH_TOKEN_KEY, successLogin } from 'app/shared/reducers/authentication';
import jwt from 'jwt-decode';
import AppHeader from 'app/shared/layout/header/header';
import { calculateScreenElementsHeight } from 'app/shared/util/screen-utils';
import { ACTION_TYPES } from 'app/shared/reducers/screen-size';
import { ShowNotifications } from 'app/modules/ednevnik/notifications/show-notifications';
import axios from 'axios';
import { webSocketConnect } from 'app/config/websocket-middleware';
import GlobalNotification from 'app/shared/layout/header/global-notification/global-notification';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  const globalNotificationState = useSelector((state: IRootState) => state.globalNotificationState);
  const screenSizeState = useSelector((state: IRootState) => state.screenSizeState);
  const authentication = useSelector((state: IRootState) => state.authentication);
  const notificationsState = useSelector((state: IRootState) => state.notificationsState);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    function updateSize() {
      dispatch({
        type: ACTION_TYPES.SCREEN_SIZE_CHANGED,
        size: [window.innerWidth, window.innerHeight],
      });
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    calculateScreenElementsHeight();
  }, [screenSizeState.screenSize, globalNotificationState, props.isAuthenticated]);

  useEffect(() => {
    if (props.isAuthenticated) {
      getWebSocketUrl().then(webSocketUrl => {
        webSocketConnect(webSocketUrl, authentication.account.id_user);
      });
    }
  }, [props.isAuthenticated]);

  const getWebSocketUrl = async () => {
    return await axios.get(`/api/notifications/websocket-url`).then(response => {
      return response.data;
    });
  };

  useEffect(() => {
    if (Storage.local.get(AUTH_TOKEN_KEY)) {
      const token = Storage.local.get(AUTH_TOKEN_KEY);

      const user = jwt(token);
      props.successLogin(user);
    }
  }, []);

  return (
    <Router basename={baseHref}>
      <div className="app-container">
        <ToastContainer
          position={toast.POSITION.TOP_LEFT}
          autoClose={APP_TOAST_MESSAGE_DURATION}
          className="toastify-container"
          toastClassName="toastify-toast"
        />
        <ErrorBoundary>
          {props.isAuthenticated && <GlobalNotification />}
          {props.isAuthenticated && (
            <AppHeader
              isAuthenticated={props.isAuthenticated}
              isAdmin={props.isAdmin}
              canSelectRole={props.canSelectRole}
              canCreateBuildingDiary={props.canCreateBuildingDiary}
              hasPermission={props.hasPermission}
              userChamberMemberTypeNotSelected={props.userChamberMemberTypeNotSelected}
              hasMultipleUserChamberMembers={props.hasMultipleUserChamberMembers}
              userName={props.displayName}
              chamberMark={props.chamberMark}
              userChamberMemberFullAuthNumber={props.userChamberMemberFullAuthNumber}
              isInProduction={props.isInProduction}
              isOpenAPIEnabled={props.isOpenAPIEnabled}
              isSupervisor={props.isSupervisor}
            />
          )}
          {props.isAuthenticated && notificationsState.notificationsShown && <ShowNotifications />}
        </ErrorBoundary>
        <div className="container-fluid view-container" id="app-view-container">
          <div className="h-100">
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  displayName: `${authentication.account.firstName} ${authentication.account.lastName}`,
  chamberMark: authentication.account.chamberMark,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isSupervisor: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SYSTEM_SUPERVISOR]),
  canSelectRole: authentication.account.canSelectRole,
  canCreateBuildingDiary: authentication.account.canCreateBuildingDiary,
  hasPermission: !hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.NO_PERMISSION]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isOpenAPIEnabled: applicationProfile.isOpenAPIEnabled,
  userChamberMemberTypeNotSelected: authentication.account?.userChamberMemberId === undefined,
  hasMultipleUserChamberMembers: authentication.account?.hasMultipleUserChamberMembers,
  userChamberMemberFullAuthNumber: authentication.account?.userChamberMemberFullAuthNumber,
});

const mapDispatchToProps = { setLocale, successLogin };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
