import './badge.scss';

import React, { PropsWithChildren } from 'react';
import { Badge as RsBadge, BadgeProps as RsBadgeProps } from 'reactstrap';

interface BadgeProps extends PropsWithChildren<RsBadgeProps> {
  textTruncate: boolean;
}

export const Badge = ({ children, textTruncate = false, ...props }: PropsWithChildren<RsBadgeProps>) => {
  return (
    <RsBadge {...(textTruncate && { className: 'overflow-hidden text-nowrap' })} {...props}>
      <div {...(textTruncate && { className: 'text-truncate' })}>{children}</div>
    </RsBadge>
  );
};
