import './header.scss';

import React, { useEffect } from 'react';
import { Nav } from 'reactstrap';

import LoadingBar from 'react-redux-loading-bar';

import { AdminMenu } from '../menus';
import AccountMenu from '../menus/account';
import { Button, Header } from 'app/shared/lib';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import CreateNewBuildingDiary from 'app/modules/ednevnik/create-new-building-diary-modal/create-new-building-diary-modal';
import RoleSelectionModal from 'app/modules/modal/role-selection-modal';
import { useHistory } from 'react-router-dom';
import { ACTION_TYPES as DIARY_DISPATCH_ACTIONS } from 'app/shared/reducers/buildingdiary';
import { ACTION_TYPES as SUBDIARY_DISPATCH_ACTIONS } from 'app/shared/reducers/buildingsubdiary';
import { ACTION_TYPES as NOTIFICATIONS_DISPATCH_ACTIONS } from 'app/shared/reducers/notifications';
import axios from 'axios';
import { showToast } from 'app/shared/reducers/toast.util';
import { toast } from 'react-toastify';
import { AUTHORITIES, messages } from 'app/config/constants';
import { downloadFileFromBase64 } from 'app/shared/util/file-utils';
import { receiveNewWebSocketMessageForNotificationUpdate } from 'app/config/websocket-middleware';
import UserChamberMemberTypeSelectionModal from 'app/modules/modal/user-chamber-member-type-selection-modal';

export interface IHeaderProps extends StateProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isSupervisor: boolean;
  canSelectRole: boolean;
  hasPermission: boolean;
  hasMultipleUserChamberMembers: boolean;
  userChamberMemberTypeNotSelected: boolean;
  userName: string;
  userChamberMemberFullAuthNumber: string;
  chamberMark: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  canCreateBuildingDiary: boolean;
}

const REGEX_WORK_LOG_URL_PATTERN = /^\/building-diaries\/\d+\/diary-components\/\d+[/\w-]{0,}$/;

const AppHeader = (props: IHeaderProps) => {
  const regex = new RegExp(REGEX_WORK_LOG_URL_PATTERN);

  const history = useHistory();
  const dispatch = useDispatch();
  const buildingDiaryState = useSelector((state: IRootState) => state.buildingDiary);
  const buildingSubdiaryState = useSelector((state: IRootState) => state.buildingSubdiary);
  const authentication = useSelector((state: IRootState) => state.authentication);
  const notificationsState = useSelector((state: IRootState) => state.notificationsState);

  useEffect(() => {
    history.listen((loc, action) => {
      if (action === 'POP') {
        dispatch({ type: DIARY_DISPATCH_ACTIONS.RESET_BUILDING_DIARY_DATA });
        dispatch({ type: SUBDIARY_DISPATCH_ACTIONS.RESET_BUILDING_SUBDIARY_DATA });
      }
    });

    if (authentication.isAuthenticated) {
      getNotificationCount(authentication.account.id_user);
    }
  }, [props.consent]);

  useEffect(() => {
    receiveNewWebSocketMessageForNotificationUpdate()?.subscribe(isUpdated => {
      if (isUpdated) {
        getNotificationCount(authentication.account.id_user);
      }
    });
  });

  useEffect(() => {
    if (props.isSupervisor && notificationsState.notificationsShown) {
      dispatch({ type: NOTIFICATIONS_DISPATCH_ACTIONS.SET_NOTIFICATIONS, shown: false });
    }
  }, [props.isSupervisor]);

  const isMainScreen = () => {
    return (
      window.location.pathname === '/' ||
      window.location.pathname === '/login' ||
      window.location.pathname === '/building-diaries' ||
      window.location.pathname === '/role-selection'
    );
  };

  const isNoPermissionPage = () => {
    return window.location.pathname === '/no-permission';
  };

  const shouldHideHomeButton = () => {
    if (buildingDiaryState.code || buildingSubdiaryState.code) {
      return false;
    }

    return true;
  };

  const isWorkLogScreenOrWorkLogChildScreen = () => {
    return regex.test(window.location.pathname);
  };

  const constructHeaderTitle = () => {
    if (buildingSubdiaryState.code && isWorkLogScreenOrWorkLogChildScreen()) {
      return `${buildingSubdiaryState.code} - ${buildingSubdiaryState.siteConstructor} - ${buildingSubdiaryState.constructorWork}`;
    } else if (buildingDiaryState.code) {
      return buildingDiaryState.code;
    }
    return 'eGrađevinski dnevnik';
  };

  const returnHome = () => {
    dispatch({ type: DIARY_DISPATCH_ACTIONS.RESET_BUILDING_DIARY_DATA });
    dispatch({ type: SUBDIARY_DISPATCH_ACTIONS.RESET_BUILDING_SUBDIARY_DATA });
    history.push('/building-diaries');
  };

  const downloadUserManual = () => {
    axios
      .get('/api/user-manuals')
      .then(response => {
        const data = response.data;
        const applicationType = data.fileType.type + '/' + data.fileType.subtype;
        downloadFileFromBase64(data.fileName, data.contentData, applicationType);
      })
      .catch(error => {
        showToast(toast.error, messages.FETCHING_DOCUMENT_ERROR);
      });
  };

  const getNotificationCount = (idUser: number) => {
    asyncCallForNotificationCount(idUser).then(response => {
      dispatch({ type: NOTIFICATIONS_DISPATCH_ACTIONS.UPDATE_NOTIFICATIONS_COUNT, payload: response.data });
    });
  };

  const asyncCallForNotificationCount = async (idUser: number) => {
    return await axios.get(`/api/notifications/${idUser}/count`);
  };

  const onNotificationsClick = () => {
    dispatch({ type: NOTIFICATIONS_DISPATCH_ACTIONS.SET_NOTIFICATIONS, shown: !notificationsState.notificationsShown });
  };

  const fetchDisplayName = () => {
    if (props.isSupervisor) {
      return props.userName;
    }

    if (props.userChamberMemberTypeNotSelected || !props.hasMultipleUserChamberMembers) {
      return props.userName;
    } else {
      return props.userChamberMemberFullAuthNumber + ' ' + props.userName;
    }
  };

  return (
    <div id="app-header">
      <LoadingBar className="loading-bar" />
      <Header>
        <a id="back-arrow-header" hidden={shouldHideHomeButton()} onClick={() => returnHome()}>
          <i className="las la-home header-home" />
        </a>
        <a id="header-info" className="header-info">
          {constructHeaderTitle()}
        </a>
        {isMainScreen() &&
          authentication.account.authorities !== AUTHORITIES.SYSTEM_SUPERVISOR &&
          props.hasPermission &&
          !props.isAdmin &&
          props.canCreateBuildingDiary && <CreateNewBuildingDiary />}
        <div className="ml-auto" />
        {props.hasPermission && !props.isAdmin && (
          <Button id="header-notifications" color="link" className="mr-3" prependIcon="las la-bell" badge="0" hidden={true} />
        )}
        {props.hasPermission && !props.isSupervisor && (
          <Button
            color="link"
            className="ml-auto mr-3"
            prependIcon="las la-bell"
            badge={notificationsState.notificationsCount}
            onClick={onNotificationsClick}
          />
        )}
        {props.hasPermission && <Button color="link" prependIcon="las la-info-circle" onClick={downloadUserManual} />}
        <div className="d-flex">
          {isMainScreen() && (props.hasPermission || (!props.hasPermission && props.canSelectRole)) && props.canSelectRole && (
            <RoleSelectionModal hasMultipleRoles={!props.hasPermission && props.canSelectRole} />
          )}
          {isNoPermissionPage() && props.canSelectRole && <RoleSelectionModal hasMultipleRoles={false} />}

          {props.hasPermission && props.hasMultipleUserChamberMembers && !props.isSupervisor && (
            <UserChamberMemberTypeSelectionModal forceUserChamberMemberTypeSelection={props.userChamberMemberTypeNotSelected} />
          )}
        </div>
        <Nav className="navbar-item">
          {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
          {props.isAuthenticated && <AccountMenu displayName={fetchDisplayName()} chamberMark={props.chamberMark} />}
        </Nav>
      </Header>
    </div>
  );
};

const mapStateToProps = ({ consent }: IRootState) => ({
  consent,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(AppHeader);
