import React from 'react';

import { Input as RsInput, InputProps as RsInputProps } from 'reactstrap';
import { uniqueId } from 'lodash';
import './toggle.scss';

interface ToggleProps extends RsInputProps {
  id?: string;
  label?: string;
}

export const Toggle = ({ label, id = uniqueId('checkbox-'), ...props }: ToggleProps) => {
  return (
    <div className="custom-control custom-switch">
      <RsInput className="custom-control-input" id={id} type="checkbox" {...props} />
      <label htmlFor={id} className="custom-control-label">
        {label}
      </label>
    </div>
  );
};
