import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ACTION_TYPES } from 'app/shared/reducers/global-notification';

const GlobalNotification = () => {
  const dispatch = useDispatch();
  const [notificationText, setNotificationText] = useState(false);
  const globalNotificationState = useSelector((state: IRootState) => state.globalNotificationState);

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      fetchNotification();
    }
  }, []);

  const getNotification = () => {
    return notificationText;
  };

  const getShouldHideNotification = () => {
    return globalNotificationState.globalNotificationShown;
  };

  const fetchNotification = () => {
    axios.get(`/api/global-notification`).then(response => {
      if (response.data !== null) {
        setNotificationText(response.data.message);

        dispatch({
          type: ACTION_TYPES.SET_GLOBAL_NOTIFICATION,
          payload: {
            shown: false,
          },
        });
      } else {
        dispatch({
          type: ACTION_TYPES.SET_GLOBAL_NOTIFICATION,
          payload: {
            shown: true,
          },
        });
      }
    });
  };

  return (
    <div id="app-notification" className="notification" hidden={getShouldHideNotification()}>
      {getNotification()}
    </div>
  );
};

export default GlobalNotification;
