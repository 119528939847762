import React from 'react';

import { FormGroup, Input as RsInput, InputProps as RsInputProps, Label } from 'reactstrap';
import { uniqueId } from 'lodash';
import './radio-button.scss';

interface RadioButtonProps extends RsInputProps {
  id?: string;
  label?: string;
}

export const RadioButton = ({ label, id = uniqueId('radio-button-'), ...props }: RadioButtonProps) => {
  return (
    <>
      <FormGroup check className="custom-radio">
        <RsInput type="radio" name="radio1" className="custom-control-input" id={id} {...props} />
        <label htmlFor={id} className="custom-control-label">
          {label ?? ''}
        </label>
      </FormGroup>
    </>
  );
};
