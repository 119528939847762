export const ACTION_TYPES = {
  SET_SHOW_CONSENT_MODAL: 'consent/SET_SHOW_CONSENT_MODAL',
  REFRESHED_BUILDING_DIARIES: 'consent/REFRESHED_BUILDING_DIARIES',
};

const initialState = {
  showConsentModal: false,
  refreshBuildingDiaries: false,
};

export type ShowConsentModalState = Readonly<typeof initialState>;

export default (state: ShowConsentModalState = initialState, action): ShowConsentModalState => {
  switch (action.type) {
    case ACTION_TYPES.SET_SHOW_CONSENT_MODAL: {
      const currentShowConsentModal = action.payload.showConsentModal;

      const shouldRefreshBuildingDiaries = currentShowConsentModal === false;

      if (state.showConsentModal !== currentShowConsentModal) {
        return {
          ...state,
          showConsentModal: currentShowConsentModal,
          refreshBuildingDiaries: shouldRefreshBuildingDiaries,
        };
      }
      return {
        ...state,
        showConsentModal: state.showConsentModal,
        refreshBuildingDiaries: shouldRefreshBuildingDiaries,
      };
    }
    case ACTION_TYPES.REFRESHED_BUILDING_DIARIES: {
      return {
        ...state,
        refreshBuildingDiaries: false,
      };
    }
    default:
      return state;
  }
};
