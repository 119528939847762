import './button.scss';

import React from 'react';
import { Button as RsButton, ButtonProps as RsButtonProps } from 'reactstrap';
import { declassify } from '../../../util';

interface ButtonProps extends RsButtonProps {
  label?: string;
  prependIcon?: string;
  appendIcon?: string;
  badge?: string;
  isLoading?: boolean;
}

export const Button = ({
  label,
  prependIcon,
  appendIcon,
  badge,
  className = '',
  isLoading = false,
  disabled = false,
  ...props
}: ButtonProps) => {
  const onClick = e => {
    e.preventDefault();
    if (props.onClick) props.onClick(e);
  };

  return (
    <RsButton
      {...props}
      disabled={disabled || isLoading}
      className={declassify(className, { 'icon-only': !label }, { 'position-relative': !!badge })}
      onClick={onClick}
    >
      {prependIcon && !isLoading ? <i className={'la-fw ' + prependIcon} /> : ''}
      {label && !isLoading ? <span className="btn-label">{label}</span> : ''}
      {appendIcon && !isLoading ? <i className={'la-fw ' + appendIcon} /> : ''}
      {badge ? (
        <div className="btn-badge">
          <span>{badge}</span>
        </div>
      ) : (
        ''
      )}
      {isLoading ? <i className="las la-circle-notch la-spin" /> : null}
    </RsButton>
  );
};
