import React from 'react';
import { noop } from 'lodash';
import './toast.scss';

interface ToastProps {
  show: boolean;
  message: string;
  headerTitle: string;
  color?: string;
  onClose?: () => void;
}

// USING THIS COMPONENT
/* To use this component you must add container in which you place Toasts. SEE SHOWCASE div element on line 60!
Container must be position fixed, have z-index, and top - bottom - right - left depending on position you decided for
toast to be shown in. This is so toasts can be stackable on each other */

export const Toast = ({ show, message, headerTitle, color = 'primary', onClose = noop }: ToastProps) => {
  return (
    <div id="toast" className={`toast ${show ? 'show toast-active' : 'hide'}`}>
      <div className={`toast-header toast-${color}`}>
        <span className="mr-md-4">{headerTitle}</span>
        <button type="button" className="close" onClick={onClose}>
          <i className="las la-times"></i>
        </button>
      </div>
      <div className={`toast-body toast-${color}`}>{message}</div>
    </div>
  );
};
