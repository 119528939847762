import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import CustomRedirect from 'app/shared/util/custom-redirect';
import CustomLoginPage from 'app/modules/login/custom-login-page';

export interface ILoginProps extends StateProps, RouteComponentProps<any> {
}

export const Login = (props: ILoginProps) => {
  const {isAuthenticated} = props;
  if (isAuthenticated) {
    return <CustomRedirect to="/"/>;
  }
  return <CustomLoginPage/>;
};

const mapStateToProps = ({authentication}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loading: authentication.loading,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Login);
