import { ConsentLocationsFragment, ConsentRolesFragment } from 'app/modules/ednevnik/add-consent-modal/consent-fragments';
import { Button } from 'app/shared/lib';
import React, { useState } from 'react';
import { BuildingDiaryConsent, Consent } from 'app/modules/ednevnik/add-consent-modal/consent-model';
import axios from 'axios';
import { showToast } from 'app/shared/reducers/toast.util';
import { toast } from 'react-toastify';
import { Notification } from 'app/modules/ednevnik/notifications/notifications-model';
import { v4 as uuid } from 'uuid';
import { NOTIFICATIONS_GROUP } from 'app/modules/ednevnik/notifications/notifications-group';
import { messages } from 'app/config/constants';

export interface NotificationItemProp {
  notification: Notification;
  idUser: number;
  setAllNotifications: any;
}

const NotificationItem = (props: NotificationItemProp) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const acceptConsent = (buildingDiary: BuildingDiaryConsent) => {
    setIsSaving(true);
    buildingDiary.checked = true;

    axios
      .post<Consent>(`/api/consents/${props.idUser}/save-from-notifications`, buildingDiary)
      .then(response => {
        showToast(toast.success, messages.CONSENT_APPROVED_FOR_PARTICIPATING_IN_BUILD);
        props.setAllNotifications(response.data);
        setIsSaving(false);
      })
      .catch(error => {
        setIsSaving(false);
        showToast(toast.error, error.response.data.errorMessage);
      });
  };

  const deactivateNotification = (idNotification: number) => {
    axios
      .delete(`/api/notifications/deactivate?idUser=${props.idUser}&idNotification=${idNotification}`)
      .then(response => {
        props.setAllNotifications(response.data);
      })
      .catch(() => {
        showToast(toast.error, messages.DELETING_ERROR);
      });
  };

  const renderStandardNotification = () => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <div className="col-md-11 p-0">
            <p className="notifications-title">{props.notification.notificationTitle}</p>
          </div>
          <div className="col-md-1 justify-content-end p-0">
            <div className="notifications-close" onClick={() => deactivateNotification(props.notification.idNotification)}>
              <i className="las la-times notification-icon-close" />
            </div>
          </div>
        </div>
        <small className="notifications-text">{props.notification.notificationText}</small>
        <br />
        <br />
        <small className="notifications-text">Datum obavijesti: {props.notification.notificationDate}</small>
      </>
    );
  };

  const renderConsentNotification = () => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <div className="col-md-11 p-0">
            <p className="notifications-title">
              {props.notification.buildingDiaryCode} - {props.notification.notificationTitle}
            </p>
          </div>
        </div>

        {props.notification?.buildingDiaries?.map(buildingDiary => {
          return (
            <div key={uuid()}>
              <small className="notification-text">
                <ConsentRolesFragment buildingDiary={buildingDiary} className="notifications-text" />
                <ConsentLocationsFragment buildingDiary={buildingDiary} className="notifications-text" />
                <div className="notifications-text mt-1">Molimo potvrdite svoju suglasnost.</div>
              </small>

              <br />
              <small className="notifications-text">Datum obavijesti: {props.notification?.notificationDate}</small>

              <div className="pt-2">
                <Button
                  label="Potvrdi"
                  size="sm"
                  color="info"
                  onClick={() => acceptConsent(buildingDiary)}
                  prependIcon="las la-check"
                  isLoading={isSaving}
                  disabled={isSaving}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="activity-item notifications-text">
      <>
        {props.notification?.notificationGroup === NOTIFICATIONS_GROUP.STANDARD_NOTIFICATION
          ? renderStandardNotification()
          : renderConsentNotification()}
        <hr />
      </>
    </div>
  );
};

export default NotificationItem;
