import React from 'react';
import { NavDropdown } from './menu-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from 'app/shared/reducers/authentication';
import { connect } from 'react-redux';
import './account.scss';
import { disconnect, PRIVATE_NOTIFICATION_TOPIC, unsubscribe } from 'app/config/websocket-middleware';

export interface IAccountMenuProps extends DispatchProps {
  displayName: string;
  chamberMark: string;
}

const AccountMenu = (props: IAccountMenuProps) => (
  <NavDropdown icon="user" name={props.displayName} id="account-menu" data-cy="accountMenu">
    {props.chamberMark && (
      <>
        <div className="dropdown-item chamber-name">{props.chamberMark}</div>
        <hr className="chamber-name-hr" />
      </>
    )}
    <a
      className="dropdown-item"
      onClick={() => {
        unsubscribe(PRIVATE_NOTIFICATION_TOPIC);
        disconnect();
        props.logout();
      }}
    >
      <FontAwesomeIcon icon="sign-out-alt" /> Odjava
    </a>
  </NavDropdown>
);

const mapDispatchToProps = { logout };

type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(AccountMenu);
