import './loader.scss';

import React, { PropsWithChildren } from 'react';

interface LoaderProps {
  isLoading: boolean;
  done: boolean;
  message?: string;
  doneMessage?: string;
}

export const Loader = ({
  children,
  done,
  isLoading,
  message = 'Molimo pričekajte...',
  doneMessage = 'Učitavanje dovršeno',
}: PropsWithChildren<LoaderProps>) => {
  return (
    <>
      {isLoading ? (
        <div className="loader">
          {done ? (
            <>
              <i className="las la-check"></i>
              <br />
              <span className="loader-message">{doneMessage}</span>
            </>
          ) : (
            <>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">{message}</span>
              </div>
              <span className="loader-message">{message}</span>
            </>
          )}
          {children}
        </div>
      ) : (
        ''
      )}
    </>
  );
};
