const FOOTER_HEIGHT = 51;

export const calculateScreenElementsHeight = () => {
  const appHeader = document.getElementById('app-header');
  const appContainer = document.getElementById('app-view-container');
  const appNotification = document.getElementById('app-notification');
  const sidebar = document.getElementsByClassName('sidebar')[0] as HTMLElement;

  let appHeaderHeight = 0;
  let appNotificationHeight = 0;

  if (appHeader) {
    appHeaderHeight = 52;
  }

  if (appNotification) {
    appNotificationHeight = appNotification.clientHeight;
  }

  if (appContainer) {
    const headerAndFooterWithNotificationHeight = appNotificationHeight + appHeaderHeight + FOOTER_HEIGHT;
    appContainer.style.height = `calc(100% - ${headerAndFooterWithNotificationHeight}px)`;
  }

  if (sidebar) {
    const headerWithNotificationHeight = appNotificationHeight + appHeaderHeight;
    sidebar.style.top = headerWithNotificationHeight + 'px';
  }
};
