import { SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { IBuildingDiary } from 'app/modules/ednevnik/building-diaries/model/building-diary.model';

export const ACTION_TYPES = {
  GET_BUILDING_DIARY_DATA: 'BuildingDiaryState/GET_BUILDING_DIARY_DATA',
  STORE_BUILDING_DIARY_DATA: 'BuildingDiaryState/STORE_BUILDING_DIARY_DATA',
  RESET_BUILDING_DIARY_DATA: 'BuildingDiaryState/RESET_BUILDING_DIARY_DATA',
};

const initialState: IBuildingDiary = {
  idBuildingDiary: null,
  constructionStartType: null,
  code: null,
  location: null,
  investors: null,
  subdiaryCount: null,
  creationDate: null,
  status: null,
  createdBy: null,
  siteConstructorValidationWarnings: false,
  siteSupervisorValidationWarnings: false,
  canBeStarted: null,
  canBeEdited: false,
  documentGeneratorTesting: false,
  reportId: null,
  anyBuildingSubdiaryIsClosed: false,
  currentIdUserChamberMember: null,
  currentUserIsActiveChamberMember: true,
  currentUserChamberMemberAuthorizationIsActive: true,
  canUserCloseOrSignBuildingDiary: false,
  canUserDownloadDiaryDocument: false,
  canUserCloseBuildingDiary: false,
  canDiaryBeDisabled: false,
  diaryDisableReason: '',
  canUserDownloadFinalVersionForDiary: false,
  currentUserHasUserSubdiaryTextWorkLogViewAccess: false,
  usePermit: null,
  hasDiaryGeneratedDocument: false,
};

export type BuildingDiaryState = Readonly<typeof initialState>;

export default (state: BuildingDiaryState = initialState, action): BuildingDiaryState => {
  switch (action.type) {
    case SUCCESS(ACTION_TYPES.GET_BUILDING_DIARY_DATA): {
      const { data } = action.payload;

      return {
        ...state,
        ...data,
      };
    }
    case ACTION_TYPES.STORE_BUILDING_DIARY_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ACTION_TYPES.RESET_BUILDING_DIARY_DATA: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export const getBuildingDiaryData = idBuildingDiary => ({
  type: ACTION_TYPES.GET_BUILDING_DIARY_DATA,
  payload: axios.get(`/api/building-diaries/${idBuildingDiary}`),
});
