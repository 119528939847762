import './calendar.scss';

import React from 'react';
import Datetime, { DatetimepickerProps } from 'react-datetime-old';
import { declassify } from '../../../util';
import { Moment } from 'moment';
import 'moment/locale/hr';

interface CalendarProps extends Omit<DatetimepickerProps, 'timeFormat' | 'input'> {
  redDots?: Moment[];
  blueDots?: Moment[];
  maxDate?: Moment;
  minDate?: Moment;
}

export const Calendar = ({ redDots = [], blueDots = [], minDate, maxDate, ...props }: CalendarProps) => {
  const renderDay = (p, currentDate, _selectedDate) => {
    const className = declassify(p.className, {
      'red-dot': !!redDots.find(redDot => redDot.isSame(currentDate, 'day')),
      'blue-dot': !!blueDots.find(blueDot => blueDot.isSame(currentDate, 'day')),
    });
    return (
      <td {...p} className={className}>
        {currentDate.date()}
      </td>
    );
  };

  const isValid = (currentDate: Moment, _selectedDate: Moment) => {
    if (maxDate && minDate) {
      return currentDate.isSameOrAfter(minDate, 'day') && currentDate.isSameOrBefore(maxDate, 'day');
    } else if (maxDate && !minDate) {
      return currentDate.isSameOrBefore(maxDate, 'day');
    } else if (!maxDate && minDate) {
      return currentDate.isSameOrAfter(minDate, 'day');
    } else {
      return true;
    }
  };

  return <Datetime input={false} timeFormat={false} locale="hr" renderDay={renderDay} isValidDate={isValid} {...props} />;
};
