import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, ModalBody, ModalHeader } from 'reactstrap';
import { Button, Input, Label, Modal, TextArea } from 'app/shared/lib';
import axios from 'axios';
import './create-new-building-diary-modal.scss';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import { NotificationOfTheCommencementOfConstructionModel } from 'app/modules/ednevnik/create-new-building-diary-modal/notification-of-the-commencement-of-construction-model';
import { Investor } from 'app/modules/ednevnik/create-new-building-diary-modal/investor';
import { Location } from 'app/modules/ednevnik/create-new-building-diary-modal/location';
import { Construction } from 'app/modules/ednevnik/create-new-building-diary-modal/construction';
import { toast } from 'react-toastify';
import { IBuildingDiary } from 'app/modules/ednevnik/building-diaries/model/building-diary.model';
import { useHistory } from 'react-router-dom';
import { isValidEdozvolaWebIdForCreatingBuildingDiary } from 'app/shared/util/string-utils';
import { showToast } from 'app/shared/reducers/toast.util';
import { messages } from 'app/config/constants';
import { ERROR_TYPE_CONSTANTS } from 'app/shared/error/error-type-constants';

const CreateNewBuildingDiary = ({ canUserCreateBuildingDiaryState }) => {
  const [webId, setWebId] = useState('');
  const [investors, setInvestors] = useState('');
  const [locations, setLocations] = useState('');
  const [constructions, setConstructions] = useState('');
  const [notificationOfTheCommencement, setNotificationOfCommencement] = useState<NotificationOfTheCommencementOfConstructionModel>();

  const [webIdError, setWebIdError] = useState('');
  const [modal, setModal] = useState(false);
  const [webIdInvalid, setWebIdInvalid] = useState(false);
  const [isWebIdRetrieved, setWebIdRetrieved] = useState(false);
  const [isFetchingWebId, setFetchingWebId] = useState(false);
  const [isSavingNewBuildingDiary, setSavingNewBuildingDiary] = useState(false);
  const history = useHistory();

  useEffect(() => {
    resetModalState();
  }, [modal]);

  const resetModalState = () => {
    setWebId('');
    setInvestors('');
    setLocations('');
    setConstructions('');
    setNotificationOfCommencement({ ...notificationOfTheCommencement });
  };

  const onModalClick = event => {
    event.preventDefault();
    toggleModal();
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const changeWebId = event => {
    if (isWebIdRetrieved && webId !== event.target.value) {
      clearValues();
      setWebIdRetrieved(false);
    }

    setWebId(event.target.value);

    if (!isValidEdozvolaWebIdForCreatingBuildingDiary(event.target.value) || event.target.value === '') {
      setWebIdInvalid(true);

      if (event.target.value === '') {
        setWebIdError(messages.WEB_ID_IS_MANDATORY);
      } else {
        setWebIdError(messages.WEB_ID_FORMAT_NOT_VALID);
      }
    } else {
      setWebIdInvalid(false);
      setWebIdError('');
      setWebIdRetrieved(false);
    }
  };

  const createInvestorsString = (fetchedInvestors: Investor[]) => {
    if (fetchedInvestors !== undefined && fetchedInvestors !== null) {
      let investorsFinal = '';

      fetchedInvestors.forEach((investor, index, array) => {
        let investorString: string;

        if (investor.firstName === null && investor.lastName === null) {
          investorString = investor.companyName + ' - ' + investor.oib;
        } else {
          investorString = investor.firstName + ' ' + investor.lastName + ' - ' + investor.oib;
        }

        if (isNotLastElementInArray(index, array.length)) {
          investorsFinal += investorString + '; \n';
        } else {
          investorsFinal += investorString;
        }
      });

      return investorsFinal;
    }
  };

  const isNotLastElementInArray = (index: number, arrayLength: number) => {
    return !(index === arrayLength - 1);
  };

  const createLocationsString = (fetchedLocations: Location[]) => {
    if (fetchedLocations !== undefined && fetchedLocations !== null) {
      let locationsFinal = '';

      let valuesWithComma = '';
      fetchedLocations.forEach((location, index, array) => {
        Object.values(location).forEach((value, valueIndex, valuesArray) => {
          if (value !== null && valueIndex !== 0) {
            if (isNotLastElementInArray(valueIndex, valuesArray.length)) {
              valuesWithComma += value + ', ';
            } else {
              valuesWithComma += value;
            }
          }
        });

        if (isNotLastElementInArray(index, array.length)) {
          locationsFinal += valuesWithComma + '; \n';
        } else {
          locationsFinal += valuesWithComma;
        }

        valuesWithComma = '';
      });

      return locationsFinal;
    }
  };

  const createConstructionsString = (fetchedConstructions: Construction[]) => {
    if (fetchedConstructions !== undefined && fetchedConstructions !== null) {
      let constructionsFinal = '';

      let valuesWithComma = '';
      fetchedConstructions.forEach((location, index, array) => {
        Object.values(location).forEach((value, valueIndex, valuesArray) => {
          if (value !== null && valueIndex !== 0) {
            if (isNotLastElementInArray(valueIndex, valuesArray.length)) {
              valuesWithComma += value + ', ';
            } else {
              valuesWithComma += value;
            }
          }
        });

        if (isNotLastElementInArray(index, array.length)) {
          constructionsFinal += valuesWithComma + '; \n';
        } else {
          constructionsFinal += valuesWithComma;
        }

        valuesWithComma = '';
      });

      return constructionsFinal;
    }
  };

  const fetchNotificationOfTheCommencementOfConstruction = () => {
    if (webId === '' || !isValidEdozvolaWebIdForCreatingBuildingDiary(webId)) {
      setWebIdError(messages.WEB_ID_IS_MANDATORY);
      setWebIdInvalid(true);
      setWebIdRetrieved(false);
      clearValues();
    } else {
      setFetchingWebId(true);

      axios
        .get(`api/edozvola/prijava-pocetka-gradenja/` + webId)
        .then(response => {
          setFetchingWebId(false);

          setWebIdError('');
          setWebIdInvalid(false);
          setWebIdRetrieved(true);

          const fetchedData: NotificationOfTheCommencementOfConstructionModel = response.data;

          setNotificationOfCommencement(prevState => {
            return {
              ...prevState,
              webId: fetchedData.webId,
              classificationMark: fetchedData.classificationMark,
              caseType: fetchedData.caseType,
              investors: fetchedData.investors,
              constructions: fetchedData.constructions,
              locations: fetchedData.locations,
              documents: fetchedData.documents,
              siteSupervisor: fetchedData.siteSupervisor,
            };
          });

          const investorsString = createInvestorsString(fetchedData.investors);
          const locationsString = createLocationsString(fetchedData.locations);
          const constructionsString = createConstructionsString(fetchedData.constructions);

          setInvestors(investorsString);
          setLocations(locationsString);
          setConstructions(constructionsString);

          if (investorsString != null || locationsString != null || constructionsString != null) {
            setWebIdError('');
            setWebIdInvalid(false);
          }
        })
        .catch(error => {
          onWebIdError(error);
        });
    }
  };

  const submitNewBuildingDiary = event => {
    event.preventDefault();
    event.stopPropagation();
    setSavingNewBuildingDiary(true);

    axios
      .post<IBuildingDiary>(`/api/building-diaries`, notificationOfTheCommencement)
      .then(response => {
        setSavingNewBuildingDiary(false);
        toggleModal();
        showToast(toast.success, messages.DIARY_SUCCESSFULLY_CREATED);

        closeModalAndClearValues();
        redirect(`/building-diaries/${response.data.idBuildingDiary}/investors`);
      })
      .catch(error => {
        setSavingNewBuildingDiary(false);
        setWebIdInvalid(true);
        clearValues();
        showToastMessage(error);
      });
  };

  const clearValues = () => {
    setInvestors('');
    setLocations('');
    setConstructions('');
    setNotificationOfCommencement(null);
  };

  const closeModalAndClearValues = () => {
    setWebId('');
    setWebIdError('');
    setWebIdInvalid(false);
    setWebIdRetrieved(false);

    clearValues();
    toggleModal();
  };

  const showToastMessage = error => {
    if (error.response.data.errorCode === ERROR_TYPE_CONSTANTS.ERROR) {
      showToast(toast.error, error.response.data.errorMessage);
    } else {
      showToast(toast.warning, error.response.data.errorMessage);
    }
  };

  const onWebIdError = error => {
    setFetchingWebId(false);
    setWebIdInvalid(true);
    setWebIdRetrieved(true);
    setWebIdError('');
    clearValues();
    showToastMessage(error);
  };

  const redirect = path => history.push(path);

  const isSubmitNewBuildingDiaryDisabled = () => {
    if (!isValidEdozvolaWebIdForCreatingBuildingDiary(webId)) {
      return true;
    } else if (isValidEdozvolaWebIdForCreatingBuildingDiary(webId) && (investors === '' || locations === '')) {
      return true;
    } else if (notificationOfTheCommencement?.siteSupervisor === null) {
      return true;
    }

    return false;
  };

  const isFetchNotificationOfTheCommencementButtonDisabled = () => {
    if (!isValidEdozvolaWebIdForCreatingBuildingDiary(webId)) {
      return true;
    } else if (isValidEdozvolaWebIdForCreatingBuildingDiary(webId) && isWebIdRetrieved) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <Button
        color="outline-primary"
        label="Novi dnevnik"
        onMouseDown={onModalClick}
        prependIcon="las la-plus-circle"
        className="ml-3"
        hidden={!canUserCreateBuildingDiaryState}
      />
      <Modal backdrop="static" isOpen={modal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={closeModalAndClearValues}>Kreiranje novog građevinskog dnevnika</ModalHeader>
        <ModalBody>
          <Container>
            <Form>
              <FormGroup as={Row}>
                <Label className="w-100" labelText="ID prijave početka građenja ili uklanjanja" />
                <Col xs={8} className="p-0">
                  <Input type="text" id="webId" value={webId} onChange={changeWebId} error={webIdError} invalid={webIdInvalid} required />
                </Col>
                <Col xs={4} className="pr-0">
                  <Button
                    label="Dohvati podatke"
                    size="lg"
                    color="info"
                    className="w-100"
                    onClick={fetchNotificationOfTheCommencementOfConstruction}
                    disabled={isFetchNotificationOfTheCommencementButtonDisabled()}
                    isLoading={isFetchingWebId}
                  />
                </Col>
              </FormGroup>

              <FormGroup as={Row}>
                <Label labelText="Investitor" />
                <TextArea disabled={true} id="investor" value={investors} />
              </FormGroup>

              <FormGroup as={Row}>
                <Label labelText="Lokacija" />
                <TextArea readOnly={true} disabled={true} rows={4} id="location" value={locations} />
              </FormGroup>
              <FormGroup as={Row}>
                <Label labelText="Opis građevine" />
                <TextArea readOnly={true} disabled={true} id="constructionDescription" value={constructions} />
              </FormGroup>
              <FormGroup as={Row}>
                <Label labelText="Broj ovlaštenja s kojim kreirate dnevnik" />
                <Input
                  type="text"
                  id="id-user-chamber-member"
                  value={notificationOfTheCommencement?.siteSupervisor?.displayName || ''}
                  required
                  disabled
                />
              </FormGroup>
              <FormGroup as={Row} className="offset-md-5 create-new-diary-buttons-float-and-margin">
                <Button label="Odustani" size="lg" color="secondary" onClick={closeModalAndClearValues} />
                <Button
                  type="submit"
                  label="Kreiraj dnevnik"
                  color="info"
                  appendIcon="las la-arrow-right"
                  size="lg"
                  disabled={isSubmitNewBuildingDiaryDisabled()}
                  onClick={submitNewBuildingDiary}
                  isLoading={isSavingNewBuildingDiary}
                />
              </FormGroup>
            </Form>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({ canUserCreateBuildingDiaryState: state.canUserCreateBuildingDiaryState });

export default connect(mapStateToProps)(CreateNewBuildingDiary);
