import './footer.scss';

import React from 'react';
import { Col, Row } from 'reactstrap';

const Footer = props => (
  <div className="footer page-content text-center d-flex justify-content-center align-items-center">
    <Row>
      <Col md="12">
        <span>Ministarstvo prostornoga uređenja, graditeljstva i državne imovine</span>
      </Col>
    </Row>
  </div>
);

export default Footer;
