import React, { useEffect, useRef, useState } from 'react';
import { LOCAL_STORAGE_KEYS } from 'app/shared/util/local-storage-keys';
import { Storage } from 'react-jhipster';
import { useHistory } from 'react-router-dom';
import { LottiePlayer } from 'lottie-web';

import animationData from './animation/loading.json';

export interface LoaderProps {
  redirect?: boolean;
}

const Animation = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then(Lottie => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });

      return () => animation.destroy();
    }
  }, [lottie]);

  return <div ref={ref} style={{ height: '25%' }} />;
};

export const Loader = (props: LoaderProps) => {
  const history = useHistory();

  useEffect(() => {
    if (props.redirect) {
      setTimeout(() => {
        if (history.location.pathname !== '/login' && Storage.local.get(LOCAL_STORAGE_KEYS.PENDING_AUTHENTICATION)) {
          Storage.local.remove(LOCAL_STORAGE_KEYS.PENDING_AUTHENTICATION);
          history.replace('/login');
        }
      }, 1000);
    }
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center fullscreen">
      <Animation />
    </div>
  );
};
