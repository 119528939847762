export const EdnevnikRegexConstants = {
  EDOZVOLA_WEB_ID: '^[P|J][0-9]{8}-[0-9]{3,7}-Z[0-9]{2,3}$',
  EDNEVNIK_CREATION_EDOZVOLA_WEB_ID: '^P[0-9]{8}-[0-9]{3,7}-Z[0-9]{2,3}$',
  EDNEVNIK_WEB_ID: '^GD[0-9]{9,}$',
};

export const isValidEdozvolaWebId = (webId: string) => {
  return isRegexValid(webId, EdnevnikRegexConstants.EDOZVOLA_WEB_ID);
};

export const isValidEdozvolaWebIdForCreatingBuildingDiary = (webId: string) => {
  return isRegexValid(webId, EdnevnikRegexConstants.EDNEVNIK_CREATION_EDOZVOLA_WEB_ID);
};

export const isValidEdnevnikCode = (webId: string) => {
  return isRegexValid(webId, EdnevnikRegexConstants.EDNEVNIK_WEB_ID);
};

export const isRegexValid = (value: string, regex: string) => {
  if (!value) {
    return false;
  }

  return new RegExp(regex).test(value);
};

export const sentenceCaseOnString = (value: string) => {
  return value && value[0].toUpperCase() + value.toLowerCase().slice(1);
};
