import './upload.scss';

import React from 'react';
import { Button } from '..';
import { noop } from 'lodash';
import { declassify } from '../../../util';

interface UploadProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size'> {
  description?: string;
  buttonColor?: string;
  onRemove?: (index: number) => void;
  onChange?: (files: File[]) => void;
  items?: File[];
  size?: string;
}

export const Upload = ({
  buttonColor = 'white',
  description = '',
  items = [],
  onRemove = noop,
  onChange = noop,
  size = '',
  multiple = false,
  ...props
}: UploadProps) => (
  <div className={declassify('upload', size)}>
    <div className="input-wrap">
      <input type="file" onChange={e => onChange(Array.from(e.target.files ?? []))} multiple={multiple} {...props} />
    </div>
    <Button label={'Odaberi dokument' + (multiple ? 'e' : '')} prependIcon="las la-file-upload" color={buttonColor} size={size} />
    {description.length ? <small className="upload-hint">{description}</small> : null}
    <div className="file-list">
      {items.map((item, ix) => (
        <div className="file-item" key={ix}>
          <i className="las la-file" />
          <span className="file-name">{item.name}</span>
          <div className="file-remove" onClick={() => onRemove(ix)}>
            <i className="las la-trash" />
          </div>
        </div>
      ))}
    </div>
  </div>
);
