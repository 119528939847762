export const ACTION_TYPES = {
  SCREEN_SIZE_CHANGED: 'LAYOUT/SCREEN-SIZE-CHANGED',
};

const initialState: any = {
  screenSize: [0, 0],
};

export type ScreenSizeState = Readonly<typeof initialState>;

export default (state: ScreenSizeState = initialState, action): ScreenSizeState => {
  switch (action.type) {
    case ACTION_TYPES.SCREEN_SIZE_CHANGED: {
      return {
        ...state,
        screenSize: action.size,
      };
    }
    default:
      return state;
  }
};
