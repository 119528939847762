import React, { useCallback, useRef } from 'react';
import { noop } from 'lodash';

import { PredictiveInputAsyncPure, PredictiveInputAsyncProps } from './PredictiveInputAsyncPure';

export function PredictiveInputAsync({ onBlur = noop, clearSearchFieldOnBlur = false, ...props }: PredictiveInputAsyncProps<any>) {
  const ref: any = useRef();
  const blur = useCallback(ev => {
    onBlur(ev);
    if (clearSearchFieldOnBlur) {
      ref.current.setState(s => ({ ...s, text: '' }));
    }
  }, []);

  return <PredictiveInputAsyncPure onBlur={blur} ref={ref} {...props} />;
}
