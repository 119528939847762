import React from 'react';

import { Collapse as RsCollapse, CollapseProps as RsCollapseProps } from 'reactstrap';

interface Collapse extends RsCollapseProps {
  isOpen: boolean;
  children: any;
}

export const Collapse = ({ children, isOpen, ...props }) => {
  return (
    <RsCollapse isOpen={isOpen} {...props}>
      {children}
    </RsCollapse>
  );
};
