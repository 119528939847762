import React from 'react';
import { v4 as uuid } from 'uuid';

export const ConsentRolesFragment = ({ buildingDiary, className = '' }) => {
  return (
    <div className={className}>
      <div>Dodani ste kao sudionik u gradnji:</div>
      {buildingDiary.roles.map((role, _) => {
        return (
          <div key={uuid()}>
            <div className="pb-2">
              <div>
                <b>- {role.description?.toUpperCase()}</b>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ConsentLocationsFragment = ({ buildingDiary, className = '' }) => {
  return (
    <div className={className}>
      na građevinskom dnevniku <b>{buildingDiary.code.toUpperCase()}</b>, {buildingDiary.location?.toUpperCase()}.
    </div>
  );
};
