import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { Observable } from 'rxjs';

let subscriber = null;
let connection: Promise<any>;
let connectedPromise: any = null;
let notificationsUpdateListener: Observable<any>;
let notificationsUpdateListenerObserver: any;
let reportStatusUpdateListener: Observable<any>;
let reportStatusUpdateListenerObserver: any;
let alreadyConnectedOnce = false;

export const PRIVATE_NOTIFICATION_TOPIC = '/user/queue/topic/private-notification';
export const REPORT_STATUS_TOPIC = '/user/queue/topic/report-status';

const createConnection = (): Promise<any> => new Promise(resolve => (connectedPromise = resolve));

const createListener = (topic): Observable<any> => {
  if (topic === PRIVATE_NOTIFICATION_TOPIC) {
    return new Observable(observer => {
      notificationsUpdateListenerObserver = observer;
    });
  } else if (REPORT_STATUS_TOPIC) {
    return new Observable(observer => {
      reportStatusUpdateListenerObserver = observer;
    });
  }
};

export let stompClient = null;

export const createStompClient = (webSocketUrl: string, userId: number) => {
  const socket = new SockJS(webSocketUrl + '/ws?userId=' + userId);
  return Stomp.over(socket);
};

export const webSocketConnect = (webSocketUrl: string, userId: number) => {
  if (connectedPromise !== null || alreadyConnectedOnce) {
    // the connection is already being established
    return;
  }
  connection = createConnection();
  notificationsUpdateListener = createListener(PRIVATE_NOTIFICATION_TOPIC);

  stompClient = createStompClient(webSocketUrl, userId);
  stompClient.debug = null;
  stompClient.connect({}, () => {
    connectedPromise('success');
    connectedPromise = null;
    alreadyConnectedOnce = true;
    subscribe(PRIVATE_NOTIFICATION_TOPIC);
  });

  return stompClient;
};

export const receiveNewWebSocketMessageForNotificationUpdate = () => notificationsUpdateListener;
export const receiveNewWebSocketMessageForReportStatusUpdate = () => reportStatusUpdateListener;

export const subscribe = topic => {
  if (topic === PRIVATE_NOTIFICATION_TOPIC) {
    notificationsUpdateListener = createListener(PRIVATE_NOTIFICATION_TOPIC);
    connection.then(() => {
      subscriber = stompClient.subscribe(topic, payload => {
        notificationsUpdateListenerObserver.next(JSON.parse(payload.body));
      });
    });
  } else if (REPORT_STATUS_TOPIC) {
    reportStatusUpdateListener = createListener(REPORT_STATUS_TOPIC);
    connection.then(() => {
      subscriber = stompClient.subscribe(topic, payload => {
        reportStatusUpdateListenerObserver.next(JSON.parse(payload.body));
      });
    });
  }
};

export const disconnect = () => {
  if (stompClient !== null) {
    if (stompClient.connected) {
      stompClient.disconnect();
    }
    stompClient = null;
  }
  alreadyConnectedOnce = false;
};

export const unsubscribe = topic => {
  if (subscriber !== null) {
    subscriber.unsubscribe(topic);
  }
};
