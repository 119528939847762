import './input.scss';

import React, { useRef } from 'react';
import {
  InputGroup as RsInputGroup,
  Input as RsInput,
  InputProps as RsInputProps,
  InputGroupAddon as RsInputGroupAddon,
  InputGroupText as RsInputGroupText,
  InputGroupAddonProps as RsInputGroupAddonProps,
} from 'reactstrap';
import { declassify } from '../../../util';

export interface InputProps extends RsInputProps {
  addonAppend?: any | any[];
  addonPrepend?: any | any[];
  addonAppendClick?: (index: number) => void;
  addonPrependClick?: (index: number) => void;
  error?: string;
}

export const Input = ({ addonAppend, addonPrepend, addonAppendClick, addonPrependClick, error, ...props }: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const appendClick = (i: number) => {
    if (addonAppendClick) {
      addonAppendClick(i);
    } else {
      inputRef.current?.focus();
    }
  };

  const prependClick = (i: number) => {
    if (addonPrependClick) {
      addonPrependClick(i);
    } else {
      inputRef.current?.focus();
    }
  };

  return (
    <WrapWithAddons
      appends={asArray(addonAppend)}
      prepends={asArray(addonPrepend)}
      appendClick={appendClick}
      prependClick={prependClick}
      error={error}
    >
      <RsInput invalid={!!(error && error.length)} innerRef={inputRef} {...props} />
    </WrapWithAddons>
  );
};

function asArray<T>(value: T | T[] | undefined): T[] {
  if (value instanceof Array) {
    return (value as unknown) as T[];
  }
  return value !== undefined ? [value] : [];
}

function Addon({ children, ...props }: RsInputGroupAddonProps) {
  return (
    <RsInputGroupAddon {...props}>
      <RsInputGroupText>{children}</RsInputGroupText>
    </RsInputGroupAddon>
  );
}

function WrapWithAddons({ appends, prepends, children, error, appendClick, prependClick }) {
  const errorFeedback = <>{error && error.length ? <div className="invalid-feedback order-last">{error}</div> : null}</>;

  return (
    <RsInputGroup className={declassify({ 'has-validation': !!(error && error.length) })}>
      {prepends.map((content, i) => (
        <Addon key={i} onClick={() => prependClick(i)} addonType="prepend">
          {content}
        </Addon>
      ))}
      {children}
      {errorFeedback}
      {appends.map((content, i) => (
        <Addon key={i} onClick={() => appendClick(i)} addonType="append">
          {content}
        </Addon>
      ))}
    </RsInputGroup>
  );
}
